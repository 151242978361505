import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { Asset } from 'src/types/assets';
import { AssetSensorsHealthStatus } from 'src/data/sensors';
import { DiagnosticsRequestsQueryParams } from 'src/types/diagnostics';
import { baseQueryWithStatusCheck } from 'src/app/baseQueryWithStatusCheck';

const baseQuery = retry(baseQueryWithStatusCheck, { maxRetries: 2 });

export const diagnosticApi = createApi({
  reducerPath: 'diagnosticApi',
  baseQuery,
  tagTypes: [
    'Sites',
    'Assets',
    'OrganizationSummary',
    'Diagnostics',
    'Sensors',
    'Feedback',
    'Resource',
    'MobileAssets',
    'MobileDiagnostics',
  ],
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getSites: builder.query<Array<any>, any>({
      query: () => {
        return { url: `diagnostic/sites_preview` };
      },
      providesTags: (result = []) => [
        ...result.map(({ site_id }) => ({ type: 'Sites', site_id } as const)),
        { type: 'Sites' as const, id: 'LIST' },
      ],
      transformResponse: (response: any) => {
        const transformedResponse = response.map((site: any) => {
          if (site.site_id === 'york') {
            return {
              site_id: site.site_id,
              site_name: site.site_name,
              latest_diagnostic_created_at: site.latest_diagnostic_created_at,
              all: {
                total: 64,
                assets: {
                  critical: 8,
                  alarm: 10,
                  healthy: 34,
                  monitor: 12,
                  na: 0,
                },
              },
              fixed: {
                total: 16,
                assets: site.assets_health_state,
              },
              mobile: {
                total: 48,
                assets: {
                  critical: 7,
                  alarm: 8,
                  monitor: 3,
                  healthy: 30,
                  na: 0,
                },
              },
            };
          }

          if (site.site_id === 'newcastle') {
            return {
              site_id: site.site_id,
              site_name: site.site_name,
              latest_diagnostic_created_at: site.latest_diagnostic_created_at,
              all: {
                total: 16,
                assets: {
                  critical: 0,
                  alarm: 0,
                  monitor: 0,
                  healthy: 0,
                  na: 16,
                },
              },
              fixed: {
                total: 16,
                assets: site.assets_health_state,
              },
              mobile: {
                total: 0,
                assets: {
                  critical: 0,
                  alarm: 0,
                  monitor: 0,
                  healthy: 0,
                  na: 0,
                },
              },
            };
          }
        });

        return transformedResponse;
      },
    }),

    getAssets: builder.query<Array<Asset>, any>({
      query: ({ site_id }) => ({
        url: `diagnostic/assets_preview?site_id=${site_id}`,
      }),
      providesTags: (result = []) => [
        ...result.map(({ asset_id }) => ({ type: 'Assets', asset_id } as const)),
        { type: 'Assets' as const, id: 'LIST' },
      ],
    }),

    getMobileAssets: builder.query<any, any>({
      query: () => ({
        url: '/sensors_data/mobile?site_id=york&filename=assets.json',
      }),
      providesTags: (result = []) => [
        ...result.map((asset: any) => ({ type: 'MobileAssets', asset_id: asset.asset_id } as const)),
        { type: 'MobileAssets' as const, id: 'LIST' },
      ],
      transformResponse: (response: any) => {
        if (response) {
          return response.data;
        } else {
          return [];
        }
      },
    }),

    getMobileDiagnostics: builder.query<any, any>({
      query: () => ({
        url: '/sensors_data/mobile?site_id=york&filename=diagnostics.json',
      }),
      providesTags: () => [{ type: 'MobileDiagnostics' }],
      transformResponse: (response: any) => {
        if (response) {
          const key = Object.keys(response.data)[0];
          return response.data[key].assets[0];
        }
      },
    }),

    getResource: builder.query<any, any>({
      query: ({ resource_id }) => ({
        url: `diagnostic/resource?resource_id=${resource_id}`,
      }),
      providesTags: () => [{ type: 'Resource' }],
    }),

    getSensors: builder.query<AssetSensorsHealthStatus, void>({
      query: () => ({ url: 'sensors_health_status' }),
      providesTags: () => [{ type: 'Sensors' }],
    }),

    getDiagnostics: builder.query<any, DiagnosticsRequestsQueryParams>({
      query: ({ site_id, assets_ids, from_date, to_date }) => {
        const searchParams = new URLSearchParams();

        if (site_id) {
          searchParams.append('site_id', site_id);
        }

        if (assets_ids) {
          searchParams.append('assets_ids', assets_ids);
        }

        if (from_date) {
          searchParams.append('from_date', from_date);
        }

        if (to_date) {
          searchParams.append('to_date', to_date);
        }

        return {
          url: `diagnostic/diagnostics?${searchParams.toString()}`,
        };
      },
      transformResponse: (response: any) => {
        const transformedResponse = Object.entries(response).sort((a, b) => {
          const dateA = Date.parse(a[0]);
          const dateB = Date.parse(b[0]);

          return dateA - dateB;
        });

        return transformedResponse;
      },
      providesTags: [{ type: 'Diagnostics' }],
    }),

    updateFeedback: builder.mutation<any, any>({
      query({ diagnostic_id, feedback_type, feedback, description }) {
        const searchParams = new URLSearchParams();
        if (diagnostic_id) {
          searchParams.append('diagnostic_id', diagnostic_id);
        }
        if (feedback_type) {
          searchParams.append('feedback_type', feedback_type);
        }
        if (feedback) {
          searchParams.append('feedback', feedback);
        }
        if (description) {
          searchParams.append('description', description);
        }

        return {
          url: `diagnostic/update_feedback?${searchParams.toString()}`,
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useGetSitesQuery,
  useGetAssetsQuery,
  useLazyGetSitesQuery,
  useLazyGetAssetsQuery,
  useLazyGetDiagnosticsQuery,
  useUpdateFeedbackMutation,
  useLazyGetResourceQuery,
  useLazyGetMobileAssetsQuery,
  useLazyGetMobileDiagnosticsQuery
} = diagnosticApi;
