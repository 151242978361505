import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  CardBody,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  mobileFleetSensorDataSelectedSchemeSelector,
  setSelectedScheme,
} from 'src/features/mobile-fleet-sensor-data/store/mobileFleetSensorData.slice';

const SchemesMenuHeader = () => {
  return (
    <HStack justifyContent="space-between" p={2}>
      <HStack h={8}>
        <Box fontWeight={600} fontSize="0.875rem">
          Schemes
        </Box>
      </HStack>
    </HStack>
  );
};

const SchemesMenuItemHead = (props: { statusColor: string; statusLabel: string; label: string }) => {
  const { statusColor, statusLabel, label } = props;

  return (
    <AccordionButton bg="#F0F2F7" py={2.5} sx={{ _hover: { bg: '#F0F2F7' } }}>
      <HStack justifyContent="space-between" w="full">
        <HStack>
          <Box fontWeight="600" fontSize="0.875rem">
            {label}
          </Box>
          <Box color={statusColor} fontWeight="600" fontSize="0.75rem">
            {statusLabel}
          </Box>
        </HStack>

        <AccordionIcon />
      </HStack>
    </AccordionButton>
  );
};

const SchemesMenuSubItem = (props: {
  id: string;
  status: string;
  label: string;
  isSelected: boolean;
  onSchemesMenuSubItemClick: (selectedScheme: any) => void;
}) => {
  const { id, status, label, isSelected, onSchemesMenuSubItemClick } = props;
  const onClick = () => onSchemesMenuSubItemClick({ id, label });

  return (
    <HStack h={12} px={4} w="full" cursor="pointer" onClick={onClick}>
      <HStack w="full">
        <Box w={2} h={2} borderRadius="full" bgColor={status}></Box>
        <Box fontSize="0.875rem" fontWeight={isSelected ? 600 : undefined} textTransform="capitalize">
          {label}
        </Box>
      </HStack>

      {isSelected ? <CheckIcon fontSize="0.75rem" color="#01152B" /> : null}
    </HStack>
  );
};

export const SchemesMenu = () => {
  const dispatch = useDispatch();
  const selectedScheme = useSelector(mobileFleetSensorDataSelectedSchemeSelector);

  const [data, setData] = useState<any>([
    {
      label: 'Oil Pump',
      statusColor: '#CC0000',
      statusLabel: 'Critical',
      schemes: [
        {
          id: 'pump_damaged_gears',
          label: 'Damaged Gears',
          status: '#CC0000',
          isSelected: selectedScheme && selectedScheme.id === 'pump_damaged_gears',
        },
      ],
    },
    {
      label: 'Differential',
      statusColor: '#CC0000',
      statusLabel: 'Critical',
      schemes: [
        {
          id: 'internal_wear_of_gear',
          label: 'Internal Gear Wear',
          status: '#CC0000',
          isSelected: selectedScheme && selectedScheme.id === 'internal_wear_of_gear',
        },
      ],
    },
    {
      label: 'Turbocharger',
      statusColor: '#11975D',
      statusLabel: 'Healthy',
      schemes: [
        {
          id: 'fractured_shaft',
          label: 'Fractured shaft',
          status: '#11975D',
          isSelected: selectedScheme && selectedScheme.id === 'fractured_shaft',
        },
      ],
    },
  ]);

  const onSchemesMenuSubItemClick = (selectedScheme: any) => {
    const updatedData = data.map((item: any) => ({
      ...item,
      schemes: item.schemes.map((scheme: any) => ({
        ...scheme,
        isSelected: scheme.id === selectedScheme.id,
      })),
    }));

    dispatch(
      setSelectedScheme({
        id: selectedScheme.id,
        label: selectedScheme.label,
      })
    );
    setData(updatedData);
  };

  useEffect(() => {
    if (!selectedScheme) {
      dispatch(
        setSelectedScheme({
          id: data[0].schemes[0].id,
          label: data[0].schemes[0].label,
        })
      );
    }
  }, []);

  return (
    <VStack
      alignItems="flex-start"
      bgColor="#E6E8EC"
      px={2}
      pb={2}
      borderRadius="lg"
      minW="17.75rem"
      maxW="17.75rem"
      height="calc(100vh - 215px)"
      gap={0}
    >
      <SchemesMenuHeader />

      <Card w="full" boxShadow={0} h="full">
        <CardBody py={2} px={0} position="relative">
          <Accordion defaultIndex={[0, 1, 2]} allowMultiple>
            {data.map((item: any, index: number) => {
              return (
                <AccordionItem border={0} key={index}>
                  <SchemesMenuItemHead
                    statusColor={item.statusColor}
                    statusLabel={item.statusLabel}
                    label={item.label}
                  />

                  <AccordionPanel p={0}>
                    <VStack w="full" alignItems="flex-start" spacing="0">
                      {item.schemes.map((item: any, index: number) => (
                        <SchemesMenuSubItem
                          key={index}
                          id={item.id}
                          status={item.status}
                          label={item.label}
                          isSelected={item.isSelected}
                          onSchemesMenuSubItemClick={onSchemesMenuSubItemClick}
                        />
                      ))}
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </CardBody>
      </Card>
    </VStack>
  );
};
