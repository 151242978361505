import React from 'react';
import { Tab, TabList, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface ComponentTabListProps {
  selectedComponent?: any;
  isComponentHasCameraSensors: boolean;
  hasCamera?: boolean;
  isMobile?: boolean;
}
export const ComponentTabList = ({
  selectedComponent,
  isComponentHasCameraSensors,
  hasCamera,
  isMobile,
}: ComponentTabListProps) => {
  const { t } = useTranslation();
  const isSensorDataTabDisabled = selectedComponent === null;

  return (
    <TabList
      bgColor="#ffffff"
      borderRadius="8px"
      width={'auto'}
      border="1px solid #e2e8f0"
      boxShadow="sm"
      zIndex={3}
      sx={{
        '& > *:last-child': { borderTopRightRadius: 8, borderBottomRightRadius: 8 },
        '& > *:first-child': { borderTopLeftRadius: 8, borderBottomLeftRadius: 8 },
        '& > *': {
          fontWeight: 500,
          bg: '#ffffff',
          _selected: { color: '#12ffe2', bg: '#021d3d', fontWeight: 600 },
        },
      }}
    >
      <Tab {...tabProps}>{t('page.studio.tabs.diagnostics.title')}</Tab>

      <Tab isDisabled={hasCamera || isSensorDataTabDisabled} {...tabProps}>
        {isSensorDataTabDisabled ? (
          <Tooltip label={t('page.studio.tabs.sensorData.tooltip')} borderRadius="md">
            {t('page.studio.tabs.sensorData.title')}
          </Tooltip>
        ) : (
          t('page.studio.tabs.sensorData.title')
        )}
      </Tab>

      {selectedComponent !== 'mobile_asset' && (
        <Tab isDisabled={!isComponentHasCameraSensors || isSensorDataTabDisabled} {...tabProps}>
          {t('page.studio.tabs.visualAI.title')}
        </Tab>
      )}

      <Tab fontWeight={500} _selected={{ color: '#12ffe2', bg: '#021d3d', fontWeight: 600 }} {...tabProps}>
        {t('page.studio.tabs.maintenance.title')}
      </Tab>
      {isMobile && <Tab {...tabProps}>{t('page.studio.tabs.siteMap.title')}</Tab>}
    </TabList>
  );
};

const tabProps = {
  fontSize: {
    base: '0.875rem',
    xl: '0.875rem',
    '1.5xl': '0.875rem',
  },
  px: {
    base: 6,
    xl: 4,
    '1.5xl': 6,
  },
  py: {
    base: 3,
    xl: 3,
    '1.5xl': 3,
  },
};
