import React from 'react';
import { createIcon, Image } from '@chakra-ui/react';
import Truck from './Truck.svg';
export const CATTruckBottomInnerLeftTire = createIcon({
  displayName: 'CATTruckBottomInnerLeftTire',
  viewBox: '0 0 48 115',
  path: [
    <path
      key="CATTruckBottomInnerLeftTire"
      d="M35.0019 113.5H11.7301C8.81256 113.5 6.24263 111.848 5.53423 109.018C3.90492 102.509 1.49992 87.6642 1.49992 56.75C1.49992 31.5997 3.09171 17.3749 4.56261 9.59917C5.59862 4.12243 10.3994 1 15.9733 1H36.5102C40.0682 1 43.0024 2.67318 43.7057 6.16098C44.9791 12.4755 46.4999 26.3751 46.4999 56.75C46.4999 88.9101 44.0374 102.98 42.1438 109.046C41.1739 112.152 38.2564 113.5 35.0019 113.5Z"
      fill="currentColor"
      fillOpacity="0.4"
      stroke="currentColor"
      strokeWidth="2"
    />,
  ],
});

export const CATTruckBottomInnerRightTire = createIcon({
  displayName: 'CATTruckBottomInnerRightTire',
  viewBox: '0 0 48 115',
  path: [
    <path
      key="CATTruckBottomInnerRightTire"
      d="M12.4979 113.5H35.7698C38.6873 113.5 41.2572 111.848 41.9656 109.018C43.5949 102.509 45.9999 87.6642 45.9999 56.75C45.9999 31.5997 44.4081 17.3749 42.9372 9.59917C41.9012 4.12243 37.1004 1 31.5266 1H10.9896C7.43163 1 4.49747 2.67318 3.79411 6.16098C2.52071 12.4755 0.999924 26.3751 0.999924 56.75C0.999924 88.9101 3.46242 102.98 5.35606 109.046C6.32594 112.152 9.24348 113.5 12.4979 113.5Z"
      fill="currentColor"
      fillOpacity="0.4"
      stroke="currentColor"
      strokeWidth="2"
    />,
  ],
});

export const CATTruckBottomOutterLeftTire = createIcon({
  displayName: 'CATTruckBottomOutterLeftTire',
  viewBox: '0 0 48 115',
  path: [
    <path
      key="CATTruckBottomOutterLeftTire-1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.7706 111.397C44.1952 110.74 43.7656 109.942 43.5343 109.018C41.905 102.509 39.5 87.6642 39.5 56.75C39.5 31.5997 41.0918 17.3749 42.5627 9.59917C43.0664 6.93616 44.4603 4.82978 46.4003 3.36833C45.0632 1.77459 42.9312 1 40.5103 1H17.1398C13.1871 1 9.44859 2.62058 7.8665 6.24282C4.93349 12.958 1 27.4295 1 56.75C1 88.7737 4.28457 103.553 6.35386 109.687C7.18834 112.161 9.55473 113.5 12.1652 113.5H39.002C41.2703 113.5 43.375 112.845 44.7706 111.397Z"
      fill="currentColor"
      fillOpacity="0.4"
    />,
    <path
      key="CATTruckBottomOutterLeftTire-2"
      d="M44.7706 111.397L45.5225 110.737L46.1284 111.429L45.4908 112.09L44.7706 111.397ZM43.5343 109.018L42.5642 109.261L42.5642 109.261L43.5343 109.018ZM42.5627 9.59917L43.5453 9.78504L43.5453 9.78504L42.5627 9.59917ZM46.4003 3.36833L47.1664 2.72561L47.8437 3.53296L47.002 4.16706L46.4003 3.36833ZM7.8665 6.24282L8.7829 6.64307L8.7829 6.64307L7.8665 6.24282ZM6.35386 109.687L7.30139 109.367L7.30139 109.367L6.35386 109.687ZM44.0187 112.056C43.3389 111.281 42.8342 110.34 42.5642 109.261L44.5044 108.775C44.6969 109.544 45.0515 110.2 45.5225 110.737L44.0187 112.056ZM42.5642 109.261C40.9072 102.641 38.5 87.7058 38.5 56.75H40.5C40.5 87.6225 42.9028 102.376 44.5044 108.775L42.5642 109.261ZM38.5 56.75C38.5 31.5634 40.0936 17.2715 41.5801 9.4133L43.5453 9.78504C42.09 17.4783 40.5 31.636 40.5 56.75H38.5ZM41.5801 9.4133C42.1313 6.49969 43.6647 4.17709 45.7986 2.56961L47.002 4.16706C45.2558 5.48248 44.0016 7.37263 43.5453 9.78504L41.5801 9.4133ZM40.5103 0C43.1279 0 45.5838 0.83926 47.1664 2.72561L45.6342 4.01106C44.5426 2.70993 42.7344 2 40.5103 2V0ZM17.1398 0H40.5103V2H17.1398V0ZM6.9501 5.84256C8.74692 1.72868 12.9501 0 17.1398 0V2C13.4241 2 10.1503 3.51248 8.7829 6.64307L6.9501 5.84256ZM0 56.75C0 27.3534 3.94172 12.7303 6.9501 5.84256L8.7829 6.64307C5.92527 13.1857 2 27.5056 2 56.75H0ZM5.40632 110.007C3.28852 103.729 0 88.8288 0 56.75H2C2 88.7187 5.28063 103.378 7.30139 109.367L5.40632 110.007ZM12.1652 114.5C9.22908 114.5 6.40837 112.977 5.40632 110.007L7.30139 109.367C7.96831 111.344 9.88038 112.5 12.1652 112.5V114.5ZM39.002 114.5H12.1652V112.5H39.002V114.5ZM45.4908 112.09C43.8463 113.797 41.4406 114.5 39.002 114.5V112.5C41.1001 112.5 42.9037 111.893 44.0504 110.703L45.4908 112.09Z"
      fill="currentColor"
    />,
  ],
});

export const CATTruckBottomOutterRightTire = createIcon({
  displayName: 'CATTruckBottomOutterRightTires',
  viewBox: '0 0 48 115',
  path: [
    <path
      key="CATTruckBottomOutterRightTires-1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.72925 111.397C4.30463 110.74 4.73428 109.942 4.96554 109.018C6.59485 102.509 8.99985 87.6642 8.99985 56.75C8.99985 31.5997 7.40806 17.3749 5.93716 9.59917C5.43341 6.93616 4.03956 4.82978 2.09953 3.36833C3.43662 1.77459 5.56869 1 7.98957 1H31.3601C35.3127 1 39.0513 2.62058 40.6333 6.24282C43.5664 12.958 47.4998 27.4295 47.4998 56.75C47.4998 88.7737 44.2153 103.553 42.146 109.687C41.3115 112.161 38.9451 113.5 36.3346 113.5H9.49787C7.22951 113.5 5.12484 112.845 3.72925 111.397Z"
      fill="currentColor"
      fillOpacity="0.4"
    />,
    <path
      key="CATTruckBottomOutterRightTires-2"
      d="M3.72925 111.397L2.97732 110.737L2.37141 111.429L3.00908 112.09L3.72925 111.397ZM4.96554 109.018L5.93561 109.261L5.93562 109.261L4.96554 109.018ZM5.93716 9.59917L4.95458 9.78504L4.95458 9.78504L5.93716 9.59917ZM2.09953 3.36833L1.33344 2.72561L0.656096 3.53296L1.49784 4.16706L2.09953 3.36833ZM40.6333 6.24282L39.7169 6.64307L39.7169 6.64307L40.6333 6.24282ZM42.146 109.687L41.1985 109.367L41.1985 109.367L42.146 109.687ZM4.48119 112.056C5.16094 111.281 5.66562 110.34 5.93561 109.261L3.99547 108.775C3.80294 109.544 3.44831 110.2 2.97732 110.737L4.48119 112.056ZM5.93562 109.261C7.59264 102.641 9.99985 87.7058 9.99985 56.75H7.99985C7.99985 87.6225 5.59707 102.376 3.99547 108.775L5.93562 109.261ZM9.99985 56.75C9.99985 31.5634 8.40623 17.2715 6.91973 9.4133L4.95458 9.78504C6.40988 17.4783 7.99985 31.636 7.99985 56.75H9.99985ZM6.91973 9.4133C6.36858 6.49969 4.8351 4.17709 2.70123 2.56961L1.49784 4.16706C3.24401 5.48248 4.49824 7.37263 4.95458 9.78504L6.91973 9.4133ZM7.98957 0C5.3719 0 2.91601 0.83926 1.33344 2.72561L2.86563 4.01106C3.95723 2.70993 5.76548 2 7.98957 2V0ZM31.3601 0H7.98957V2H31.3601V0ZM41.5497 5.84256C39.7529 1.72868 35.5497 0 31.3601 0V2C35.0757 2 38.3496 3.51248 39.7169 6.64307L41.5497 5.84256ZM48.4998 56.75C48.4998 27.3534 44.5581 12.7303 41.5497 5.84256L39.7169 6.64307C42.5746 13.1857 46.4998 27.5056 46.4998 56.75H48.4998ZM43.0935 110.007C45.2113 103.729 48.4998 88.8288 48.4998 56.75H46.4998C46.4998 88.7187 43.2192 103.378 41.1985 109.367L43.0935 110.007ZM36.3346 114.5C39.2708 114.5 42.0915 112.977 43.0935 110.007L41.1985 109.367C40.5315 111.344 38.6195 112.5 36.3346 112.5V114.5ZM9.49787 114.5H36.3346V112.5H9.49787V114.5ZM3.00908 112.09C4.65353 113.797 7.05927 114.5 9.49787 114.5V112.5C7.39976 112.5 5.59615 111.893 4.44943 110.703L3.00908 112.09Z"
      fill="currentColor"
    />,
  ],
});

export const CATTruckTopLeftTire = createIcon({
  displayName: 'CATTruckTopLeftTire',
  viewBox: '0 0 53 120',
  path: [
    <path
      key="CATTruckTopLeftTire"
      d="M16.9394 1H41.0303C42.41 1 44.1819 1.2772 45.2038 2.80588C46.4661 4.69405 48.1518 8.6928 49.5311 17.2798C50.9136 25.8874 52 39.187 52 59.75C52 80.313 50.9136 93.6126 49.5311 102.22C48.1518 110.807 46.4661 114.806 45.2038 116.694C44.1819 118.223 42.41 118.5 41.0303 118.5H19.254C15.3173 118.5 11.3101 116.998 9.38145 113.233C5.88156 106.401 1 91.2378 1 59.75C1 32.6507 3.04643 17.698 4.8646 9.75461C6.1729 4.0388 11.3184 1 16.9394 1Z"
      fill="currentColor"
      fillOpacity="0.4"
      stroke="currentColor"
      strokeWidth="2"
    />,
  ],
});

export const CATTruckTopRightTire = createIcon({
  displayName: 'CATTruckTopRightTire',
  viewBox: '0 0 53 120',
  path: [
    <path
      key="CATTruckTopRightTire"
      d="M36.0606 1H11.9697C10.59 1 8.81814 1.2772 7.79617 2.80588C6.53388 4.69405 4.84815 8.6928 3.46894 17.2798C2.08641 25.8874 1 39.187 1 59.75C1 80.313 2.08641 93.6126 3.46894 102.22C4.84815 110.807 6.53387 114.806 7.79617 116.694C8.81814 118.223 10.59 118.5 11.9697 118.5H33.746C37.6827 118.5 41.6899 116.998 43.6185 113.233C47.1184 106.401 52 91.2378 52 59.75C52 32.6507 49.9536 17.698 48.1354 9.75461C46.8271 4.0388 41.6816 1 36.0606 1Z"
      fill="currentColor"
      fillOpacity="0.4"
      stroke="currentColor"
      strokeWidth="2"
    />,
  ],
});

export const CATTruckBottomView = (props: any) => <Image src={Truck} {...props} />;
