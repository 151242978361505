import React from 'react';
import { VStack, HStack, Box } from '@chakra-ui/react';
import { indicatorStyle, labelStyle, explanationStyle } from './MapIndex.styles';

type MapIndexItemPropsType = {
  color: string;
  label: string;
  explanation?: string;
};

export default function MapIndexItem({ label, color, explanation }: MapIndexItemPropsType) {
  return (
    <VStack justifyContent={'flex-start'} alignItems={'flex-start'} py={1} zIndex={2}>
      <HStack>
        <Box background={color} sx={indicatorStyle} />
        <Box sx={labelStyle}>{label}</Box>
      </HStack>
      {explanation && (
        <HStack sx={explanationStyle}>
          <Box>{explanation}</Box>
        </HStack>
      )}
    </VStack>
  );
}
