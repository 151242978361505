import React from 'react';
import { Box, Hide, HStack } from '@chakra-ui/react';
import { DMChartIcon } from 'src/components/Icons';
import { PreferencesPopover } from 'src/features/maintenance-data/maintenance-chart/components/PreferencesPopover';
import { MAINTENANCE_CHART_CONTEXT } from 'src/features/maintenance-data/maintenance-chart/MaintenanceChart';
import TrendsSensorsFilterPopover from 'src/features/sensor-fusion/TrendsSensorsFilterPopover';
import { ChartDatePicker } from 'src/features/visual-ai/components/ChartDatePicker';
import { upperCase } from 'lodash';

export const MobileFleetSensorDataHeader = (props: { schemeName?: string }) => {
  const { schemeName } = props;

  return (
    <HStack justifyContent="space-between" p={2}>
      <HStack h={8}>
        <DMChartIcon fontSize="1.5rem" />
        <Box fontWeight={600} fontSize="0.875rem">
          Scheme for <span style={{ textTransform: 'capitalize' }}>{schemeName}</span>
        </Box>
      </HStack>

      <HStack spacing={0}>
        <Hide below="1.5xl">
          <PreferencesPopover
            context={MAINTENANCE_CHART_CONTEXT.SensorFusion}
            mb={0}
            isDisabled
            _disabled={{
              color: 'black',
            }}
          />
        </Hide>

        <ChartDatePicker
          isInline
          buttonSize="xs"
          fromDate={new Date('2024-09-27')}
          toDate={new Date('2024-10-27')}
          minDate={new Date('27-09-27')}
          updateFromDate={() => {
            return;
          }}
          isFetching={true}
          position="relative"
          top={0}
          _disabled={{
            color: 'black',
          }}
        />

        <TrendsSensorsFilterPopover
          traces={[]}
          trendOptions={[]}
          vibrationSensorOptions={[]}
          operationalSensorOptions={[]}
          setTrendOptions={() => {
            return;
          }}
          setVibrationSensorOptions={() => {
            return;
          }}
          setOperationalSensorOptions={() => {
            return;
          }}
          isFetching={true}
          height="32px"
          _disabled={{
            color: 'black',
          }}
        />
      </HStack>
    </HStack>
  );
};
