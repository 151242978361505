import React from 'react';
import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { setSelectedScheme } from 'src/features/mobile-fleet-sensor-data/store/mobileFleetSensorData.slice';
import { useLocation, useNavigate } from 'react-router-dom';

interface SchemaProps {
  schema: any;
  idx: number;
}

const Schema = ({ schema, idx }: SchemaProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const onViewDataClick = (schemeId: string) => {
    const searchParams = new URLSearchParams(location.search);

    if (['Internal Gear Wear', 'Damaged Gears', 'Fractured Shaft'].includes(schemeId)) {
      if (schemeId === 'Internal Gear Wear') {
        dispatch(
          setSelectedScheme({
            id: 'internal_wear_of_gear',
            label: 'Internal Gear Wear',
          })
        );
      }

      if (schemeId === 'Damaged Gears') {
        dispatch(
          setSelectedScheme({
            id: 'pump_damaged_gears',
            label: 'Damaged Gears',
          })
        );
      }

      if (schemeId === 'Fractured Shaft') {
        dispatch(
          setSelectedScheme({
            id: 'fractured_shaft',
            label: 'Fractured shaft',
          })
        );
      }

      searchParams.set('tabIndex', '1');
      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      py="0.5rem"
      px="1rem"
      cursor="pointer"
      _hover={{ bg: '#f0f2f782' }}
      bg={(idx + 1) % 2 === 0 ? '#F0F2F7' : 'initial'}
    >
      <Box display="flex" alignItems="center" gap="0.5rem">
        <Box w="0.5rem" h="0.5rem" borderRadius="full" bg={`health.${schema.health_status.toLowerCase()}`} />
        <Text fontSize="14px" fontWeight={500}>
          {schema.schema_id}
        </Text>
      </Box>
      <Button
        variant="unstyled"
        display="flex"
        flexDirection="column"
        fontSize="12px"
        fontWeight={600}
        color="#6667DD"
        onClick={() => onViewDataClick(schema.schema_id)}
      >
        <Text>View</Text>
        <Text>Data</Text>
      </Button>
    </Box>
  );
};

interface PartBoxProps {
  part: any;
}

export const PartBox = ({ part }: PartBoxProps) => {
  return (
    <Box bg="#fff" p="1rem" borderRadius="8px">
      <HStack mb="0.5rem">
        <Text fontSize="14px" fontWeight={600}>
          {part.part_id}
        </Text>
        <Text fontSize="12px" fontWeight={600} color={`health.${part.health_status.toLowerCase()}`}>
          {part.health_status}
        </Text>
      </HStack>
      {part.schemas.map((schema: any, idx: number) => (
        <Schema schema={schema} key={idx} idx={idx} />
      ))}
    </Box>
  );
};
