import { useState, useEffect } from 'react';

const INITIAL_STATE = {
  all: 0,
  fixed: 0,
  mobile: 0,
};

export const useSiteAssetTotalsByType = (sitesData: any) => {
  const [totals, setTotals] = useState({ ...INITIAL_STATE });

  useEffect(() => {
    if (!sitesData) return;

    const calculateTotals = () => {
      const amount = { ...INITIAL_STATE };

      for (const { all, fixed, mobile } of sitesData) {
        amount.all += all?.total ?? 0;
        amount.fixed += fixed?.total ?? 0;
        amount.mobile += mobile?.total ?? 0;
      }

      return amount;
    };

    setTotals(calculateTotals());
  }, [sitesData]);

  return totals;
};
