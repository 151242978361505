import { MapViewTypeEnum, MapViewLayerEnum, LayoutEnum, PositionEnum } from 'src/types/newFolderStructure';
import { FLEX_CONTAINER, POSITION_ABSOLUTE } from 'src/constants/newFolderStructure/common/dynamicStyling';

export const API_KEY = process.env.REACT_APP_GMP_KEY;

// Disabled/Enabled GMP map options
export const GMP_OPTIONS = {
  disableDefaultUI: false, // Disables all default UI
  zoomControl: false, // Enables zoom control separately
  mapTypeControl: false, // Hides map type selector
  streetViewControl: false, // Hides the Street View Pegman
  fullscreenControl: false, // Hides the fullscreen button
  rotateControl: false, // Hides the rotate control
  scaleControl: false, // Hides the scale control
  mapTypeId: 'satellite',
};

export const DEFAULT_MAP_VALUES = {
  ZOOM: 16,
  STROKE_WEIGHT: 5,
  MAP_TYPE: MapViewTypeEnum.PATH_MAP,
  MAP_LAYER: MapViewLayerEnum.TIRE_WEAR_HEATMAP,
};

export const MAP_CONTAINER_STYLE = {
  width: '100%',
  height: 'calc(100vh - 58px)',
  zIndex: 0,
};

export const MAP_CONTROLLERS_DEFAULT_LAYOUT = {
  zoomController: {
    layout: FLEX_CONTAINER[LayoutEnum.VERTICAL],
    position: POSITION_ABSOLUTE[PositionEnum.TOP_RIGHT],
  },
  layer: { layout: FLEX_CONTAINER[LayoutEnum.HORIZONTILE], position: POSITION_ABSOLUTE[PositionEnum.BOTTOM_LEFT] },
  mapIndex: { layout: FLEX_CONTAINER[LayoutEnum.VERTICAL], position: POSITION_ABSOLUTE[PositionEnum.BOTTOM_RIGHT] },
  MapLineIndex: {
    layout: FLEX_CONTAINER[LayoutEnum.HORIZONTILE],
    position: POSITION_ABSOLUTE[PositionEnum.BOTTOM_CENTER],
  },
};
