import { Annotations } from 'plotly.js';

const tickFont = {
  color: '#021d3d',
  size: 12,
  family: 'Montserrat',
};

const xAxisBase = {
  linecolor: 'rgba(2, 29, 61, 0.3)',
  tickfont: tickFont,
};

const yAxisBase = {
  zeroline: false,
  linecolor: 'rgba(2, 29, 61, 0.3)',
  showline: true,
  showgrid: false,
  tickfont: tickFont,
};

const categoryOrderArray = {
  tickvals: [0, 5, 15, 25, 35],
  ticktext: ['', 'Healthy', 'Monitor', 'Alarm', 'Critical'],
};

export const getAxesConfig = (datasetLength: number) => {
  switch (datasetLength) {
    case 1:
      return {
        xaxis: {
          ...xAxisBase,
          domain: [0, 1],
        },
        yaxis: {
          ...yAxisBase,
          ...categoryOrderArray,
          domain: [0, 1],
        },
      };

    case 2:
      return {
        xaxis: {
          ...xAxisBase,
          domain: [0, 1],
        },
        yaxis: {
          ...yAxisBase,
          domain: [0, 0.5],
        },
        yaxis2: {
          ...yAxisBase,
          ...categoryOrderArray,
          domain: [0.5, 1],
        },
      };

    case 3:
      return {
        xaxis: {
          ...xAxisBase,
          domain: [0, 1],
        },
        yaxis: {
          ...yAxisBase,
          domain: [0, 0.33],
        },
        yaxis2: {
          ...yAxisBase,
          domain: [0.33, 0.66],
        },
        yaxis3: {
          ...yAxisBase,
          ...categoryOrderArray,
          domain: [0.66, 1],
        },
      };

    case 4:
      return {
        xaxis: {
          ...xAxisBase,
          domain: [0, 1],
        },
        yaxis: {
          ...yAxisBase,
          domain: [0, 0.21],
        },
        yaxis2: {
          ...yAxisBase,
          domain: [0.25, 0.46],
        },
        yaxis3: {
          ...yAxisBase,
          domain: [0.5, 0.71],
        },
        yaxis4: {
          ...yAxisBase,
          ...categoryOrderArray,
          domain: [0.75, 1],
        },
      };

    case 5:
      return {
        xaxis: {
          ...xAxisBase,
          domain: [0, 1],
        },
        yaxis: {
          ...yAxisBase,
          domain: [0, 0.16],
        },
        yaxis2: {
          ...yAxisBase,
          domain: [0.2, 0.36],
        },
        yaxis3: {
          ...yAxisBase,
          domain: [0.4, 0.56],
        },
        yaxis4: {
          ...yAxisBase,
          domain: [0.6, 0.76],
        },
        yaxis5: {
          ...yAxisBase,
          ...categoryOrderArray,
          domain: [0.8, 1],
        },
      };

    default:
      break;
  }
};

export const getAnnotations = (dataset: any, datasetLength: number) => {
  const yAnnotationPositionDelta = 1 / dataset.length;
  const annotations: Partial<Annotations>[] = dataset.map((item: any, index: number) => {
    return {
      x: 0.01,
      y: index !== datasetLength - 1 ? (index + 1) * yAnnotationPositionDelta - 0.035 : 1,
      text: item.title === 'Part Status' ? 'AI Fusion Trend' : item.title,
      xref: 'paper',
      yref: 'paper',
      xanchor: 'left',
      yanchor: 'bottom',
      showarrow: false,
      font: {
        color: '#01152B',
        family: 'Montserrat',
        weight: 600,
        size: 14,
      },
      bgcolor: 'rgba(255, 255, 255, 1)',
    };
  });

  return {
    annotations: [...annotations],
  };
};

const minDate = '2024-09-28 00:24:17';
const maxDate = '2024-10-27 23:32:20';

export const getShapes = (datasetLength: number) => {
  const formulaAxesIndex = datasetLength;

  const shapes = [
    {
      type: 'rect',
      x0: minDate,
      x1: maxDate,
      y0: 0,
      y1: 10,
      xref: `x`,
      yref: `y${formulaAxesIndex}`,
      line: {
        color: 'transparent',
      },
      fillcolor: 'rgba(17, 151, 93, 0.2)',
    },
    {
      type: 'rect',
      x0: minDate,
      x1: maxDate,
      y0: 10,
      y1: 20,
      xref: `x`,
      yref: `y${formulaAxesIndex}`,
      line: {
        color: 'transparent',
      },
      fillcolor: 'rgba(241, 191, 66, 0.2)',
    },
    {
      type: 'rect',
      x0: minDate,
      x1: maxDate,
      y0: 20,
      y1: 30,
      xref: `x`,
      yref: `y${formulaAxesIndex}`,
      line: {
        color: 'transparent',
      },
      fillcolor: 'rgba(255, 137, 27, 0.2)',
    },
    {
      type: 'rect',
      x0: minDate,
      x1: maxDate,
      y0: 30,
      y1: 40,
      xref: `x`,
      yref: `y${formulaAxesIndex}`,
      line: {
        color: 'transparent',
      },
      fillcolor: 'rgba(204, 0, 0, 0.2)',
    },
  ];

  return {
    shapes: shapes,
  };
};
