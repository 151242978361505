import React from 'react';
import { useParams } from 'react-router-dom';
import { FixedMachineComponentsPage } from './FixedMachineComponentsPage';
import { MobileMachineComponentsPage } from './MobileMachineComponentsPage';

export const MachineComponentsPage = () => {
  const { siteId, machineId } = useParams<string>();
  const isMobileAsset = siteId === 'york' && machineId === '22385';

  return <>{isMobileAsset ? <MobileMachineComponentsPage /> : <FixedMachineComponentsPage />}</>;
};
