import React from 'react';
import { DMMachineIcon } from 'src/components/Icons';
import { Box, HStack, SimpleGrid } from '@chakra-ui/react';
import { Asset, AssetsView } from 'src/types/assets';
import { MobileMachineItem } from './MobileMachineItem';
import { FormattedText } from 'src/components/FormattedText';

type MobileMachinesGroupProps = {
  title: string;
  assets: Array<any>;
};

export const MobileMachinesGroup = ({
  title,
  assets,
}: MobileMachinesGroupProps) => {
  return (
    <Box w="full" bgColor="#E6E8EC" p={4} borderRadius="lg">
      <HStack color="rgb(100,115,134)" fontSize="0.875rem" fontWeight={600} mb={4}>
        <DMMachineIcon fontSize="1.5rem" />
        <Box>
          <FormattedText label={title} values={{ count: assets.length }} />
        </Box>
      </HStack>

      <SimpleGrid
        columns={{ base: 1 }}
        spacing={2}
      >
        {assets.map((item, index) => (
          <MobileMachineItem
            key={index}
            item={item}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};
