import React, { Dispatch, SetStateAction } from 'react';
import {
  Box,
  Button,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  StackProps,
  ComponentWithAs,
  IconProps,
  Icon,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { DMArrowDownIcon, DMArrowUpIcon, DMGridViewIcon, DMListViewIcon } from 'src/components/Icons';
import { AssetsView } from 'src/types/assets';

interface DropdownItemProps {
  label: string;
  isSelected: boolean;
  props: StackProps;
  handleClick: () => void;
  icon: ComponentWithAs<'svg', IconProps>;
}

const DropdownItem = ({ label, isSelected, props, handleClick, icon }: DropdownItemProps) => (
  <HStack
    justifyContent="space-between"
    alignItems="center"
    pl="1rem"
    pr="1.25rem"
    gap={0}
    _hover={{ background: '#E6E8EC80' }}
    cursor="pointer"
    onClick={handleClick}
    {...props}
  >
    <HStack alignItems="center">
      <Icon as={icon} w="24px" h="24px" />
      <Box fontWeight={isSelected ? '600' : '500'} fontSize="0.875rem">
        {label}
      </Box>
    </HStack>
    {isSelected && <CheckIcon color="#01152B" />}
  </HStack>
);

interface AssetsViewDropdownProps {
  selectedAssetsView: AssetsView;
  setSelectedAssetsView: Dispatch<SetStateAction<AssetsView>>;
  isDisabled?: boolean;
}

export const AssetsViewDropdown = ({
  selectedAssetsView,
  setSelectedAssetsView,
  isDisabled,
}: AssetsViewDropdownProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const handleItemClick = (value: AssetsView) => {
    setSelectedAssetsView(value);
  };

  const isGridViewSelected = selectedAssetsView === 'grid';

  const isListViewSelected = selectedAssetsView === 'list';

  const buttonLabel = isGridViewSelected ? 'Grid' : 'List';

  return (
    <Popover isOpen={isOpen} onClose={onClose} placement="bottom-start" gutter={4}>
      <PopoverTrigger>
        <Button
          rightIcon={isOpen ? <DMArrowUpIcon w="24px" h="24px" /> : <DMArrowDownIcon w="24px" h="24px" />}
          leftIcon={isGridViewSelected ? <DMGridViewIcon w="24px" h="24px" /> : <DMListViewIcon w="24px" h="24px" />}
          variant="naked"
          fontSize="0.875rem"
          size="lg"
          height="47px"
          width="130px"
          gap={0}
          justifyContent="space-between"
          fontWeight="500"
          borderRadius="0.5rem"
          paddingX="1rem"
          border={isOpen ? '1px solid #6667DD' : '1px solid #B1B9C3'}
          bgColor={isOpen ? '#6667DD' : 'none'}
          color={isOpen ? '#fff' : '#01152B'}
          onClick={onToggle}
          isDisabled={isDisabled}
        >
          {buttonLabel}
        </Button>
      </PopoverTrigger>

      <PopoverContent border="1px solid #B1B9C3" borderRadius="0.5rem" w="240px" boxShadow="0px 2px 8px 0px #021D3D1F">
        <PopoverBody p={0}>
          <DropdownItem
            label="Grid"
            isSelected={isGridViewSelected}
            props={{
              borderTopRadius: '0.5rem',
              pt: '1.25rem',
              pb: '0.75rem',
            }}
            handleClick={() => handleItemClick('grid')}
            icon={DMGridViewIcon}
          />
          <DropdownItem
            label="List"
            isSelected={isListViewSelected}
            props={{
              borderBottomRadius: '0.5rem',
              pt: '0.75rem',
              pb: '1.25rem',
            }}
            handleClick={() => handleItemClick('list')}
            icon={DMListViewIcon}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
