import { Box, Divider, HStack, VStack } from '@chakra-ui/react';
import { HealthStatusIndicator } from 'src/components/StateIndicator';
import React from 'react';

export const MobileComponentSidebarItem = (props: {
  component: any;
  setSelectedComponent: any;
  setActiveTabIndex?: any;
  selectedComponent: any;
}) => {
  const handleClick = () => {
    props.setActiveTabIndex && props.setActiveTabIndex(0);
    if (props.component.component_name === 'Tires') {
      props.setSelectedComponent(props.component);
    } else {
      props.setSelectedComponent(null);
    }
  };
  const isSelected = props.component.component_name === props.selectedComponent?.component_name;
  return (
    <Box
      onClick={() => {
        handleClick();
      }}
    >
      <HStack
        px={4}
        py={3}
        spacing={4}
        bgColor="#fff"
        borderRadius="lg"
        border={isSelected ? '2px solid #7071f3' : '2px solid #fff'}
        justifyContent="space-between"
        cursor="pointer"
        _hover={{
          border: isSelected ? '2px solid rgba(112, 113, 243, 1)' : '2px solid rgba(112, 113, 243, 0.4)',
        }}
      >
        <VStack alignItems="flex-start" spacing={1} w="full">
          <HStack fontSize="14px" fontWeight={500} mb={1} lineHeight={1.1} w="full" justifyContent="space-between">
            <Box>{props.component.component_name}</Box>
          </HStack>
          <HStack spacing={2} alignItems="center" w="full" justifyContent="space-between">
            <HStack spacing={2} alignItems="center">
              {props.component.health_status && (
                <HealthStatusIndicator type={props.component.health_status.toLowerCase()} />
              )}
            </HStack>
          </HStack>
        </VStack>
      </HStack>
      <Divider />
    </Box>
  );
};
