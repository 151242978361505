export const truckStyle = { height: '500px', top: 0, left: 0, z: 1 };
export const tagStyle = { zIndex: 3, fontWeight: 600 };
export const tiresTopStyle = { top: '68px' };
export const tiresBottomStyle = { bottom: '70px' };
export const tiresTopLeftOffestValue = '68px';
export const tiresTopRightOffestValue = '70px';
export const tiresBottomInnerOffestValue = '102px';
export const tiresBottomOutterOffestValue = '53px';

export const tiresStyle = {
  height: '137px',
  width: 'fit-content',
  position: 'absolute',
  zIndex: 1,
  _hover: {
    transform: 'scale(1.1)', // Scale up the SVG on hover
    cursor: 'pointer', // Change cursor on hover
    opacity: '1',
  },
};
