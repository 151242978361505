import { heatMapColors, speedMapColors } from '../../common/MapColors';

export const SPEED_MAP_INDEX = [
  { label: 'Crawl', color: speedMapColors.still, condition: '', explanation: 'Speed: 0-10 km/h ' },
  { label: 'Slow', color: speedMapColors.slow, condition: '', explanation: 'Speed: 10-30 km/h ' },
  { label: 'Moderate', color: speedMapColors.average, condition: '', explanation: 'Speed: 30-50 km/h' },
  { label: 'Fast', color: speedMapColors.speedy, condition: '', explanation: 'Speed: 50+ km/h' },
];

export const TIRE_WEAR_HEATMAP_MAP_INDEX = [
  {
    label: 'Severe Tire Wear',
    color: heatMapColors.critical,
    explanation: 'Signifying intensive use or harsh terrain conditions.',
  },
  {
    label: 'Medium Tire Wear',
    color: heatMapColors.alarm,
    explanation: 'Indicating more frequent use or moderately rough terrain.',
  },
  {
    label: 'Mild Tire Wear',
    color: heatMapColors.healthy,
    explanation: 'Minimal damage, representing less usage or smoother terrain.',
  },
];
