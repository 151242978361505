import React from 'react';
import { Image } from '@chakra-ui/react';
import { MIME_TYPE } from './const';
import { subDays, format, addYears } from 'date-fns';
import { MMMM_DD_YYYY_FORMAT, MMMM_DD_FORMAT } from 'src/const';

export function mapAuthCodeToMessage(authCode: string) {
  switch (authCode) {
    case 'auth/wrong-password':
      return 'Password provided is not corrected';

    case 'auth/user-not-found':
      return 'User with provided email is not found';

    case 'auth/invalid-email':
      return 'Email provided is invalid';

    case 'auth/too-many-requests':
      return 'Access to this account has been temporarily disabled due to many failed login attempts';

    case 'auth/weak-password':
      return 'The provided password is invalid. It must be at least six characters.';

    case 'auth/invalid-action-code':
    case 'auth/expired-action-code':
      return 'The reset password link is invalid. Please navigate to Login page and click "Forgot Password" to request a new link.';

    default:
      return 'Unknown error';
  }
}

export const accessObjectByString = (o: any, s: any) => {
  s = s.replace(/\[(\w+)\]/g, '.$1');
  s = s.replace(/^\./, '');
  const a = s.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

export const truncateString = (str: string, maxLength = 20) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + '...';
  } else {
    return str;
  }
};

export const isDateInRange = (dateString: string, startDate: Date, endDate: Date) => {
  const date = new Date(dateString);
  return date >= startDate && date <= endDate;
};

export const renderEvidenceResource = (type: string, resource: string) => {
  switch (type) {
    case MIME_TYPE.Image_PNG:
      return <Image src={resource} />;
    case MIME_TYPE.Video_MP4:
      return (
        <video controls autoPlay loop>
          <source type={type} src={resource} />
        </video>
      );
    default:
      return null;
  }
};

export const getFormattedDateRange = (numberOfDays: any) => {
  const pastDate = subDays(new Date(), numberOfDays);
  const todayDate = format(new Date(), MMMM_DD_FORMAT);

  if (numberOfDays === '365') {
    const nextYear = addYears(pastDate, 1);
    return `${format(pastDate, MMMM_DD_YYYY_FORMAT)} - ${format(nextYear, MMMM_DD_YYYY_FORMAT)}`;
  }
  return `${format(pastDate, MMMM_DD_FORMAT)} - ${todayDate}`;
};

export function debounce(func: any, delay: number) {
  let timeout: any;
  return function (...args: any[]) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), delay);
  };
}

export const truncateAssetName = (name: any, isNotMonitored: any) => {
  const startLength = isNotMonitored ? 12 : 20;
  const endLength = 7;

  if (name.length <= startLength + endLength) return name;

  const startPart = name.slice(0, startLength);
  const endPart = name.slice(-endLength);

  return `${startPart}...${endPart}`;
};
