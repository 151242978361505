import React, { RefObject, useEffect, useMemo, useState } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { MaintenanceChartPopover } from 'src/features/maintenance-data/maintenance-chart/components/MaintenanceChartPopover';
import {
  enrichTasks,
  extractTasks,
  organizeGanttTasks,
  updateTaskColors,
} from 'src/features/maintenance-data/maintenance-chart/utils';
import { Pagination } from 'src/features/maintenance-data/maintenance-chart/components/Pagination';
import { MaintenanceTask, MaintenanceTasks } from 'src/features/maintenance-data/maintenance-chart/types';
import { TASK_RECTANGLE_COLOR } from 'src/features/maintenance-data/maintenance-chart/const';
import { Legend } from 'src/features/maintenance-data/maintenance-chart/components/Legend';
import { useLazyGetMaintenanceDataQuery } from 'src/app/api/maintenanceApi';
import { useParams } from 'react-router-dom';
import { Loading } from 'src/components/Loading';

const initialGrid = {
  left: '4px',
  right: '4px',
  top: '6px',
  bottom: '6px',
};

const getOption = (
  tasks: MaintenanceTasks,
  dataZoomParams: Record<string, number>,
  isDiagnosticsContext: boolean,
  xAxisMin?: string,
  xAxisMax?: string,
  grid?: Record<string, string>
) => {
  return {
    grid: grid || initialGrid,
    xAxis: {
      show: false,
      type: 'time',
      min: xAxisMin || undefined,
      max: xAxisMax || undefined,
    },
    lazyUpdate: true,
    yAxis: {
      show: false,
      inverse: true,
      type: 'category',
      data: ['Category 1'],
    },
    dataZoom: isDiagnosticsContext
      ? [
          {
            type: 'inside',
            filterMode: 'none',
            ...dataZoomParams,
          },
        ]
      : undefined,
    series: [
      {
        type: 'custom',
        renderItem: (params: any, api: any) => {
          const categoryIndex = api.value(0);
          const startTime = api.coord([api.value(1), categoryIndex]);
          const endTime = api.coord([api.value(2), categoryIndex]);
          const height = api.size([0, 1])[1] * 0.4;

          return {
            type: 'rect',
            shape: {
              x: startTime[0],
              y: startTime[1] - height / 2,
              width: startTime[0] === endTime[0] ? 6 : endTime[0] - startTime[0],
              height: height,
              r: 10,
            },
            style: api.style({
              fill: api.value(4),
            }),
          };
        },
        itemStyle: {
          borderColor: '#01152b',
          borderWidth: 1,
        },
        encode: {
          x: [1, 2],
          y: 0,
        },
        data: tasks?.map((task: MaintenanceTask) => [
          task.categoryIndex,
          task.startTime,
          task.endTime,
          task.taskName,
          task.color,
          task.details,
        ]),
      },
    ],
  };
};

enum POPOVER_HORIZONTAL_OFFSET {
  onDiagnostics = 300,
  onSensorFusion = 315,
}

enum POPOVER_VERTICAL_OFFSET {
  onDiagnostics = 140,
  onSensorFusion = 130,
}

interface MobileMaintenanceChartProps {
  selectedComponent: any;
  xAxisMin?: string | undefined;
  xAxisMax?: string | undefined;
  context?: MAINTENANCE_CHART_CONTEXT;
  wrapperRef: RefObject<HTMLDivElement>;
  diagnosticsChartRef?: React.RefObject<ReactECharts>;
  maintenanceChartRef?: React.RefObject<ReactECharts>;
  assetId: string;
}

export enum MAINTENANCE_CHART_CONTEXT {
  Diagnostics = 'diagnostics',
  SensorFusion = 'sensorFusion',
}

export const MobileMaintenanceChart = ({
  selectedComponent,
  xAxisMin,
  xAxisMax,
  context = MAINTENANCE_CHART_CONTEXT.Diagnostics,
  wrapperRef,
  diagnosticsChartRef,
  maintenanceChartRef,
  assetId,
}: MobileMaintenanceChartProps) => {
  const { siteId } = useParams<string>();
  const [tasks, setTasks] = useState<MaintenanceTasks>([]);
  const [taskPages, setTaskPages] = useState<Array<Array<MaintenanceTask>>>([]);
  const [activeTaskPage, setActiveTaskPage] = useState<number>(0);
  const [selectedTask, setSelectedTask] = useState<MaintenanceTask>();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState({ x: 0, y: 0 });
  const [option, setOption] = useState<any>();
  const [dataZoom, setDataZoom] = useState<Record<string, number>>({ start: 0, end: 100 });
  const isDiagnosticsContext = useMemo(() => context === MAINTENANCE_CHART_CONTEXT.Diagnostics, []);
  const [fetchMaintenanceData, { data: maintenanceData, isLoading: isLoadingMaintenanceData }] =
    useLazyGetMaintenanceDataQuery();

  const onEventsHandlers = {
    click: (params: any) => {
      const clickedTaskIndex = params.dataIndex;
      const wrapperBoundingRect = wrapperRef.current!.getBoundingClientRect();

      const x = isDiagnosticsContext
        ? params.event.event.clientX - wrapperBoundingRect.left - POPOVER_HORIZONTAL_OFFSET.onDiagnostics
        : params.event.event.clientX - wrapperBoundingRect.left - POPOVER_HORIZONTAL_OFFSET.onSensorFusion;

      const y = isDiagnosticsContext
        ? params.event.event.clientY -
          wrapperBoundingRect.top +
          wrapperBoundingRect.top -
          POPOVER_VERTICAL_OFFSET.onDiagnostics
        : params.event.event.clientY - wrapperBoundingRect.top - POPOVER_VERTICAL_OFFSET.onSensorFusion;

      setPopoverPosition({ x, y });
      setIsPopoverOpen(true);

      setSelectedTask(params.data);
      setTasks(updateTaskColors(tasks, clickedTaskIndex));
    },
    datazoom: (e: any) => {
      if (e.batch?.[0]) {
        const { start, end, startValue, endValue } = e.batch[0];
        const updatedZoom: Record<string, number> = {};

        if (start !== undefined && end !== undefined) {
          updatedZoom.start = start;
          updatedZoom.end = end;
        }

        if (startValue !== undefined && endValue !== undefined) {
          updatedZoom.startValue = startValue;
          updatedZoom.endValue = endValue;
        }

        if (Object.keys(updatedZoom).length) {
          setDataZoom(updatedZoom);
        }
      }
    },
    finished: () => {
      if (diagnosticsChartRef && maintenanceChartRef) {
        if (maintenanceChartRef.current && diagnosticsChartRef.current) {
          const maintenanceChartInstance = maintenanceChartRef.current.getEchartsInstance();
          const diagnosticsChartInstance = diagnosticsChartRef.current.getEchartsInstance();

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          echarts.connect([maintenanceChartInstance, diagnosticsChartInstance]);

          diagnosticsChartRef.current.getEchartsInstance().on('restore', (e: any) => {
            diagnosticsChartRef.current?.getEchartsInstance().dispatchAction({
              type: 'dataZoom',
              start: 0,
              end: 100,
            });

            setDataZoom({ start: 0, end: 100 });
          });
        }
      }
    },
    restore: () => setDataZoom({ start: 0, end: 100 }),
  };

  const onPopoverClose = () => {
    const updatedTasks = tasks.map((task: MaintenanceTask) => ({
      ...task,
      color: TASK_RECTANGLE_COLOR,
    }));

    setTasks(updatedTasks);
    setIsPopoverOpen(false);
    setSelectedTask(undefined);
  };

  const onPrevClick = () => {
    if (taskPages && activeTaskPage > 0 && taskPages[activeTaskPage - 1].length) {
      setTasks(taskPages[activeTaskPage - 1]);
      setActiveTaskPage((prevPage) => prevPage - 1);
    }
  };

  const onNextClick = () => {
    if (taskPages && activeTaskPage < taskPages.length - 1 && taskPages[activeTaskPage + 1].length) {
      setTasks(taskPages[activeTaskPage + 1]);
      setActiveTaskPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (maintenanceData) {
      const { data } = maintenanceData;
      const getAssetData = (assetName: string) =>
        assetName && assetName in data ? { [assetName]: data[assetName] } : {};
      const processedTasks: MaintenanceTasks = enrichTasks(extractTasks(getAssetData(assetId)));

      if (xAxisMin && xAxisMax) {
        const organizedTasks = organizeGanttTasks(processedTasks, xAxisMin, xAxisMax);

        setTaskPages(organizedTasks);
        setTasks(organizedTasks.length ? organizedTasks[activeTaskPage] : []);
      }
    }
  }, [selectedComponent, maintenanceData, activeTaskPage, xAxisMin, xAxisMax]);

  useEffect(
    () => setOption(getOption(tasks, dataZoom, isDiagnosticsContext, xAxisMin, xAxisMax)),
    [tasks, dataZoom, xAxisMin, xAxisMax]
  );

  useEffect(() => {
    fetchMaintenanceData({ site_id: siteId }, true);
  }, []);

  const chartElement = useMemo(() => {
    return (
      <ReactECharts
        ref={maintenanceChartRef}
        echarts={echarts}
        option={option}
        onEvents={onEventsHandlers}
        style={{
          width: '100%',
          height: '30px',
        }}
      />
    );
  }, [option]);

  const noDataPlaceholderElement = useMemo(() => {
    return (
      <Box fontSize="0.875rem" color="#55687D" p={2} textAlign="center" w="full">
        No maintenance data available. Please upload data in order to see it.
      </Box>
    );
  }, []);

  return (
    <Box px={4}>
      {isLoadingMaintenanceData ? (
        <Loading py={5} />
      ) : (
        <>
          <Box bgColor="rgba(230, 232, 236, 1)" borderRadius="lg">
            <HStack spacing={0} justifyContent={tasks?.length ? 'stretch' : 'center'}>
              {tasks?.length && option ? (
                <>
                  {chartElement}

                  <MaintenanceChartPopover
                    isPopoverOpen={isPopoverOpen}
                    onPopoverClose={onPopoverClose}
                    popoverPosition={popoverPosition}
                    selectedTask={selectedTask}
                  />

                  {taskPages.length > 1 ? (
                    <Pagination
                      onPrevClick={onPrevClick}
                      onNextClick={onNextClick}
                      taskPages={taskPages}
                      activeTaskPageIndex={activeTaskPage}
                      lastTaskPageIndex={taskPages.length}
                    />
                  ) : null}
                </>
              ) : (
                noDataPlaceholderElement
              )}
            </HStack>
          </Box>
          <Legend />
        </>
      )}
    </Box>
  );
};
