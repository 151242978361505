import React from 'react';
import { Box, HStack, Tooltip, Tag } from '@chakra-ui/react';
import { ComponentFusionTrendChartHead } from 'src/pages/machines/components/ComponentFusionTrendChartHead';
import DatePicker from 'react-datepicker';
import { HEALTH_STATUS, REGULAR_DATE_FORMAT } from 'src/const';
import { format } from 'date-fns';
import { DMArrowDownIcon, DMCircleInfoIcon } from 'src/components/Icons';
import { colors } from 'src/theme/foundations/colors';
import { MobileDiagnosticsChart } from './MobileDiagnosticsChart';
import { PreferencesPopover } from 'src/features/maintenance-data/maintenance-chart/components/PreferencesPopover';
import { MAINTENANCE_CHART_CONTEXT } from 'src/features/maintenance-data/maintenance-chart/MaintenanceChart';

interface MobileComponentDiagnosticsProps {
  selectedComponent?: any;
  diagnostics: any;
  assetHealthStatus: string;
  assetId: string;
}

export const MobileComponentDiagnostics = ({
  selectedComponent,
  diagnostics,
  assetHealthStatus,
  assetId,
}: MobileComponentDiagnosticsProps) => {
  return (
    <Box
      position="sticky"
      top={{ base: '9.8rem', xl: '9.9375rem', '1.5xl': '9.9375rem' }}
      bgColor="#fff"
      borderBottomRadius="8px"
      pt={0}
      pb={0}
      flex="1"
    >
      <Box borderBottomRadius="8px" w="full" h="full">
        <Box bgColor="#E6E8EC" borderRadius="8px" w="full" p={2} h="full" display="flex" flexDirection="column">
          <HStack justifyContent="space-between" w="full">
            <ComponentFusionTrendChartHead selectedComponent={selectedComponent} assetName={''}>
              <Tag
                bgColor={`health.${assetHealthStatus.toLowerCase()}`}
                borderRadius="full"
                fontSize="12px"
                fontWeight={600}
                color={colors.DMWhite}
                textTransform="capitalize"
              >
                {assetHealthStatus.toLowerCase() === HEALTH_STATUS.NOT_AVAILABLE ? 'Not Monitored' : assetHealthStatus}{' '}
                {format(new Date(), REGULAR_DATE_FORMAT)}
              </Tag>
            </ComponentFusionTrendChartHead>
            <HStack fontWeight={600} fontSize="0.875rem" pr={3} spacing={5}>
              <Tooltip
                label="The health status is determined by the most severe diagnostic of each day"
                borderRadius="lg"
                placement="top"
                textAlign="center"
                fontSize="0.75rem"
                lineHeight="15px"
                w="16.5rem"
                py="0.3rem"
              >
                <Box
                  w="2.5rem"
                  h="2rem"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  position="relative"
                  top="-1.5px"
                  left="15px"
                  borderRadius="8px"
                  _hover={{ background: '#B1B9C34D' }}
                  cursor="pointer"
                >
                  <DMCircleInfoIcon fontSize="1.5rem" />
                </Box>
              </Tooltip>

              <PreferencesPopover context={MAINTENANCE_CHART_CONTEXT.Diagnostics} />

              <HStack spacing={1} mb={1}>
                <Box>From</Box>
                <HStack spacing={0}>
                  <DatePicker
                    selected={new Date('2024.09.27')}
                    onChange={() => {
                      // nothing
                    }}
                    dateFormat={REGULAR_DATE_FORMAT}
                    disabled
                  />
                  <DMArrowDownIcon />
                </HStack>
              </HStack>
              <HStack spacing={1} mb={1}>
                <Box>To</Box>
                <HStack spacing={0}>
                  <DatePicker
                    selected={new Date('2024.10.28')}
                    onChange={() => {
                      // nothing
                    }}
                    dateFormat={REGULAR_DATE_FORMAT}
                    disabled
                  />
                  <DMArrowDownIcon />
                </HStack>
              </HStack>
            </HStack>
          </HStack>

          <Box bgColor="#ffffff" borderRadius="lg" w="full" p={2} flex="1">
            <Box w="full" h="full">
              <MobileDiagnosticsChart diagnostics={diagnostics} assetId={assetId} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
