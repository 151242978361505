export const mobileAssetTireHealthContainerStyle = {
  background: '#E6E8EC',
  w: '100%',
  p: 2,
  h: 'full',
  borderRadius: '8px',
  justifyContent: 'stretch',
  alignItems: 'stretch',
};

export const mobileAssetTireHealthHeaderStyle = { fontWeight: 600, fontSize: '24px', padding: 2 };
