import { Box, Image, Hide, HStack } from '@chakra-ui/react';
import React from 'react';
import { ComponentsSidebarContent } from 'src/pages/machines/components/components-sidebar/ComponentsSidebarContent';
import { ComponentsSidebarHead } from 'src/pages/machines/components/components-sidebar/ComponentsSidebarHead';
import { ShowAllComponentSidebarItem } from '../components/components-sidebar/ShowAllComponentSidebarItem';
import mobileAssetImg from 'src/assets/images/mobile-asset.png';

export const MobileComponentsSidebar = (props: {
  assetName: string;
  selectedComponent?: any;
  setSelectedComponent: any;
  components: any;
  onClick?: any;
  element: (component: any, index: number) => JSX.Element;
}) => {
  const handleAllComponentsClick = () => {
    props.onClick && props.onClick();
    props.setSelectedComponent(null);
  };
  return (
    <Box h="full">
      <Box
        h={{
          base: 0,
          xl: 'calc(100vh - 29.1rem)',
          '1.5xl': 'calc(100vh - 29.1rem)',
        }}
        borderRadius="8px"
        minW="19.75rem"
        w="19.75rem"
        bg="#E6E8EC"
        overflowY="auto"
        borderBottom="0.5rem solid #E6E8EC"
        borderTop="0.5rem solid #E6E8EC"
        display="flex"
        flexDirection="column"
        borderBottomRadius="8px"
        mb={6}
      >
        <Hide below="xl">
          <ComponentsSidebarHead components={props.components} assetName={props.assetName} />
        </Hide>

        <Box p={2}>
          <Hide below="xl">
            <ShowAllComponentSidebarItem
              onClick={handleAllComponentsClick}
              component={props.components}
              selectedComponent={props.selectedComponent}
            />
          </Hide>
        </Box>
        <Box flex="1">
          <ComponentsSidebarContent components={props.components} element={props.element} />
        </Box>
      </Box>

      <Box maxWidth="19.75rem" overflowY="auto">
        <HStack w="full" px={6} fontWeight={600} position="sticky" bgColor="#E6E8EC" borderTopRadius="8px">
          <Box
            pt={4}
            pb={4}
            w="full"
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {props.assetName}
          </Box>
        </HStack>

        <HStack justifyContent="center" fontWeight={600} position="sticky" bgColor="#E6E8EC" borderBottomRadius="8px">
          <Box display="flex" justifyContent="center" alignItems="center" style={{ width: '284px', height: '11rem' }}>
            <Image
              src={mobileAssetImg}
              borderRadius="md"
              style={{ width: '100%', height: '100%', marginBottom: '16px' }}
            />
          </Box>
        </HStack>
      </Box>
    </Box>
  );
};
