import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_MAP_VALUES } from 'src/components/newFolderStructure/assets-studio/mobile-fleet/site-map/site-map-config/config';
import { Segment, MobileAssetLineMapData, MapViewLayerEnum, LatLng } from 'src/types/newFolderStructure';

type mapStateType = {
  selectedSegment: Segment | null;
  // selectedMobileAsset: MobileAssetLineMapData;
  speedMapData: Segment[] | null; // tmp
  selectedMapViewLayer: MapViewLayerEnum;
  heatMapData: any[]; //@TODO:replace with correct type
  mapInstance: google.maps.Map | null;
  zoom: number;
  strokeWeight: number;
  center: LatLng;
};

const initialState: mapStateType = {
  selectedSegment: null,
  // selectedMobileAsset: gmpMockLineData[0],
  speedMapData: null,
  selectedMapViewLayer: DEFAULT_MAP_VALUES.MAP_LAYER,
  heatMapData: [],
  mapInstance: null,
  zoom: DEFAULT_MAP_VALUES.ZOOM,
  strokeWeight: DEFAULT_MAP_VALUES.STROKE_WEIGHT,
  center: {
    lat: 11.13792667,
    lng: -72.58582833,
  },
};

const siteMapSlice = createSlice({
  initialState,
  name: 'siteMap',
  reducers: {
    setGMPInstance(state, action) {
      state.mapInstance = action.payload;
    },
    setHeatMapData(state, action) {
      state.heatMapData = action.payload;
    },
    setSpeedMapData(state, action) {
      state.speedMapData = action.payload;
    },
    setSelectedSegment(state, action) {
      state.selectedSegment = action.payload;
    },
    setSelectedMapViewLayer(state, action) {
      state.selectedMapViewLayer = action.payload;
    },
    setZoom(state, action) {
      state.zoom = action.payload;
    },
    setStrokeWeight(state, action) {
      state.zoom = action.payload;
    },
    setCenter(state, action) {
      state.center = action.payload;
    },
    resetMap() {
      return initialState;
    },
  },
});

export const siteMapSliceReducer = siteMapSlice.reducer;
export const {
  setHeatMapData,
  setSelectedMapViewLayer,
  setSelectedSegment,
  setGMPInstance,
  setZoom,
  setStrokeWeight,
  setCenter,
  setSpeedMapData,
  resetMap,
} = siteMapSlice.actions;
