import React, { useCallback } from 'react';
import { HeatmapLayer } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { SiteMapPolyline } from 'src/components/newFolderStructure/assets-studio/mobile-fleet';
import { GMP_OPTIONS } from 'src/components/newFolderStructure/assets-studio/mobile-fleet/site-map/site-map-config/config';
import { HEAT_MAP_GRANDIENT } from 'src/constants/newFolderStructure/mobile-fleet/site-map';

export const useRenderSiteMapData = () => {
  const { speedMapData, mapInstance, heatMapData, zoom, strokeWeight } = useSelector(
    (state: RootState) => state.siteMap
  );

  // Dynamically adjust heatmap radius based on zoom level
  const getHeatmapRadius = useCallback(() => {
    if (zoom < 15) return 5; // Larger radius for closer zoom
    return 10; // Default radius for normal zoom
  }, []);

  const renderMulticoloredPolylines = () =>
    speedMapData?.map((segment, index) => (
      <SiteMapPolyline key={index} index={index} segment={segment} strokeWeight={strokeWeight} />
    ));

  const renderHeatMap = () => {
    const heatmapOptions = {
      ...GMP_OPTIONS,
      radius: getHeatmapRadius(),
      maxIntensity: 3, // Maximum weight for intensity
      opacity: 0.6,
      // gradient: HEAT_MAP_GRANDIENT, // heatmap with overlay
    };
    return heatMapData.length > 0 && mapInstance && <HeatmapLayer data={heatMapData} options={heatmapOptions} />;
  };

  return { renderMulticoloredPolylines, renderHeatMap };
};
