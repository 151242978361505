import React from 'react';
import { Button } from '@chakra-ui/react';
import { DMArrowDownIcon, DMConveyorBeltBoxes } from 'src/components/Icons';

export const BusinessInsightsDropdown = () => {
  return (
    <Button
      rightIcon={<DMArrowDownIcon w="24px" h="24px" />}
      leftIcon={<DMConveyorBeltBoxes w="24px" h="24px" />}
      variant="naked"
      fontSize="0.875rem"
      size="lg"
      height="47px"
      width="176px"
      gap={0}
      justifyContent="space-between"
      fontWeight="500"
      borderRadius="0.5rem"
      paddingX="1rem"
      border={'1px solid #B1B9C3'}
      bgColor={'none'}
      color={'#01152B'}
      cursor="initial"
    >
      Fixed Assets
    </Button>
  );
};
