import { Polyline } from '@react-google-maps/api';
import React from 'react';
import { Segment } from 'src/types/newFolderStructure';
import { useDispatch } from 'react-redux';
import { setSelectedSegment } from 'src/app/slices/siteMapSlice';
import { speedMapColors } from 'src/constants/newFolderStructure/mobile-fleet/site-map';

type SiteMapPolylinePropsType = {
  strokeWeight: number;
  segment: Segment;
  index: number;
};

export const SiteMapPolyline = ({ strokeWeight, segment, index }: SiteMapPolylinePropsType) => {
  const dispatch = useDispatch();
  return (
    <React.Fragment key={segment.id}>
      {/* Shadow polyline with semi-transparent color */}
      <Polyline
        path={segment.path}
        options={{
          strokeColor: speedMapColors[segment.speedDefinition?.toLowerCase() as 'still'], // Shadow color
          strokeOpacity: 0.3,
          strokeWeight: strokeWeight + 10, // Slightly larger for shadow effect
          zIndex: 1,
        }}
        onMouseOver={() => {
          dispatch(setSelectedSegment(segment));
        }} // Show tooltip on hover
        onMouseOut={() => dispatch(setSelectedSegment(null))} // Hide tooltip on mouse out
      />
      {/* Actual polyline with the specified color */}
      <Polyline
        path={segment.path}
        onClick={() => console.log(index, segment.id)}
        options={{
          strokeColor: speedMapColors[segment.speedDefinition?.toLowerCase() as 'still'],
          strokeOpacity: 0.7,
          strokeWeight: strokeWeight,
          zIndex: 2, // Higher z-index to render above shadows
        }}
      />
      {/* @TODO:add tooltips */}
      {/* <InfoWindow
        position={segment.path[segment.path.length - 1]} // Show at the first coordinate of the segment
        onCloseClick={() => setSelectedSegment(null)} // Close InfoWindow on click
      >
        <MapTooltip
          data={segment.path[segment.path.length - 1].lat + '.' + segment.path[segment.path.length - 1].lng}
        />
      </InfoWindow> */}
    </React.Fragment>
  );
};
