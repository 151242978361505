import React, { Suspense, useEffect, useState } from 'react';
import { Toolbar } from 'src/components/Toolbar';
import { Box, Fade, Hide, HStack, Show, TabPanel, TabPanels, Tabs, useToast } from '@chakra-ui/react';
import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getAssetById, getAssets, getSiteById } from 'src/app/queries';
import * as Sentry from '@sentry/react';
import {
  diagnosticsDateFormat,
  HEALTH_STATUS,
  INITIAL_FILTERS,
  SENSOR_TYPE,
  SENSORS_DATA_TYPE,
  STATUS_CHANGED,
  TOAST_STATUS,
  FAILURE_NAME,
} from 'src/const';
import { useLazyGetDiagnosticsQuery } from 'src/app/api/diagnosticApi';
import { useTranslation } from 'react-i18next';
import {
  getAmountByStatus,
  getComponentAmountByCamera,
  getComponentDiagnostics,
  getTotalComponentWithStatusChanged,
} from 'src/pages/machines/utils';
import { ComponentsSidebar } from 'src/pages/machines/components/components-sidebar/ComponentsSidebar';
import { ComponentSidebarItem } from 'src/pages/machines/components/components-sidebar/ComponentSidebarItem';
import {
  useLazyGetSensorsRMSQuery,
  useLazyGetSpectralAccelerationDataQuery,
  useLazyGetSpectralVelocityDataQuery,
} from 'src/app/api/sensorsApi';
import SensorsTabs from 'src/pages/machines/components/SensorsTabs';
import { ComponentsMobileMenu } from 'src/pages/machines/components/ComponentsMobileMenu';
import { format, getMonth, getYear, sub } from 'date-fns';
import { Header } from 'src/components/Header';
import { healthStatusToNumberMap } from 'src/pages/machines/const';
import { ComponentDiagnostics } from 'src/pages/machines/components/ComponentDiagnostics';
import { ComponentTabList } from 'src/pages/machines/components/ComponentTabList';
import { css } from '@emotion/react';
import { isDateInRange } from 'src/utils';
import { MachineSensorsModal } from 'src/pages/machines/modals/MachineSensorsModal';
import Fallback from 'src/components/Fallback';
import { ErrorBoundary } from 'react-error-boundary';
import { Loading } from 'src/components/Loading';
import { ComponentFailuresList } from 'src/pages/machines/components/ComponentFailuresList';
import { locations } from 'src/app/locations';
import { AssetSensorsHealthStatus } from 'src/types/assets';
import { VisualAIContainer as VisualAI } from 'src/features/visual-ai/VisualAIContainer';
import { MaintenanceTable } from 'src/features/maintenance-data/maintenance-table/MaintenanceTable';
import { SensorsDataDropdown } from 'src/pages/machines/components/SensorsDataDropdown';

export const FixedMachineComponentsPage = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { siteId, machineId, componentId } = useParams<string>();
  const navigate = useNavigate();
  const { site } = getSiteById(siteId);
  const { asset } = getAssetById(siteId, machineId);
  const { assets } = getAssets(siteId);
  const tabIndex = Number(searchParams.get('tabIndex'));
  const group = searchParams.get('group');
  const siteLastVerified = site?.latest_diagnostic_created_at;
  const assetHealthStatus = asset?.health_status;
  const assetLatestDiagnosticDate = asset?.latest_diagnostic_created_at;

  const [fetchDiagnostics, { data: diagnostics, isLoading, isFetching }] = useLazyGetDiagnosticsQuery();
  const [
    fetchTrendDiagnostics,
    { data: trendDiagnostics, isLoading: isLoadingTrendDiagnostics, isFetching: isFetchingTrendDiagnostics },
  ] = useLazyGetDiagnosticsQuery();

  const [diagnosticsData, setDiagnosticsData] = useState<any>([]);

  const [fetchSensorsRMS] = useLazyGetSensorsRMSQuery({});
  const [fetchSpectralAccelerationData] = useLazyGetSpectralAccelerationDataQuery({});
  const [fetchSpectralVelocityData] = useLazyGetSpectralVelocityDataQuery({});

  const defaultFromDateValue = sub(new Date(), { months: 6 });
  const defaultToDateValue = new Date();

  const [fromDate, setFromDate] = useState<Date>(defaultFromDateValue);
  const [toDate, setToDate] = useState<Date>(defaultToDateValue);
  const [filteredDiagnostics, setFilteredDiagnostics] = useState<any>([]);

  const [selectedSensors, setSelectedSensors] = useState<string>('all');
  const [selectedStatuses, setSelectedStatuses] = useState<Record<string, boolean>>(INITIAL_FILTERS);
  const selectedStatusesKeys = Object.keys(selectedStatuses).filter((key) => selectedStatuses[key]);
  const [selectedStatusChanged, setSelectedStatusChanged] = useState<Record<string, boolean>>(STATUS_CHANGED);

  const [components, setComponents] = useState<any>([]);
  const [allComponents, setAllComponents] = useState<any>([]);
  const [selectedComponent, setSelectedComponent] = useState<any>();
  const [selectedDiagnostics, setSelectedDiagnostics] = useState([]);

  const [activeChartPoint, setActiveChartPoint] = useState<any>();
  const [activeSensorsDataType, setActiveSensorsDataType] = useState<string>(SENSORS_DATA_TYPE.PI);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(tabIndex || 0);
  const [revision, setRevision] = useState<number>(0);

  const [isPageOpened, setPageOpened] = useState<boolean>(false);
  const [selectedComponentId, setSelectedComponentId] = useState<number>(0);

  const [isComponentHasCameraSensors, setComponentHasCameraSensors] = useState<boolean>(false);
  const [isComponentHasVibrationSensors, setComponentHasVibrationSensors] = useState<boolean>(false);
  const [isMachineHasOtherSensors, setMachineHasOtherSensors] = useState<boolean>(false);

  useEffect(() => {
    setActiveTabIndex(tabIndex);
  }, [tabIndex]);

  useEffect(() => {
    const { sensors_health_status: assetSensors } = asset || [];
    const { component_id: selectedComponentId } = selectedComponent || {};

    if (selectedComponentId && assetSensors?.length) {
      const componentVibrationSensors = assetSensors.filter(
        (sensor: AssetSensorsHealthStatus) =>
          sensor.component_id === selectedComponentId &&
          [SENSOR_TYPE.Vibrations as string, SENSOR_TYPE.Vibration as string].includes(sensor.sensor_type)
      );

      const componentCameraSensors = assetSensors.filter(
        (sensor: AssetSensorsHealthStatus) =>
          sensor.component_id === selectedComponentId && [SENSOR_TYPE.Camera as string].includes(sensor.sensor_type)
      );

      const machineOtherSensors = assetSensors.filter(
        (sensor: AssetSensorsHealthStatus) =>
          ![SENSOR_TYPE.Vibrations as string, SENSOR_TYPE.Vibration as string, SENSOR_TYPE.Camera as string].includes(
            sensor.sensor_type
          )
      );

      setComponentHasVibrationSensors(!!componentVibrationSensors.length);
      setComponentHasCameraSensors(!!componentCameraSensors.length);
      setMachineHasOtherSensors(!!machineOtherSensors.length);
    }
  }, [asset, selectedComponent]);

  const selectAvailableSensorDataType = () => {
    if (isMachineHasOtherSensors || isComponentHasVibrationSensors) {
      setActiveSensorsDataType(SENSORS_DATA_TYPE.PI);
    }
    if (isComponentHasCameraSensors) {
      setActiveSensorsDataType(SENSORS_DATA_TYPE.Camera);
    }
  };

  useEffect(() => {
    if (!isComponentHasCameraSensors) {
      setActiveTabIndex(0);
    }

    if (!isComponentHasVibrationSensors || !isMachineHasOtherSensors) {
      setActiveTabIndex(0);
    }
  }, [isComponentHasCameraSensors]);

  useEffect(() => {
    if (
      (activeSensorsDataType === SENSORS_DATA_TYPE.RMS || activeSensorsDataType === SENSORS_DATA_TYPE.FFT) &&
      !isComponentHasVibrationSensors
    ) {
      selectAvailableSensorDataType();
    }

    if (activeSensorsDataType === SENSORS_DATA_TYPE.PI && !isMachineHasOtherSensors) {
      selectAvailableSensorDataType();
    }

    if (activeSensorsDataType === SENSORS_DATA_TYPE.Camera && !isComponentHasCameraSensors) {
      selectAvailableSensorDataType();
    }
  }, [isComponentHasVibrationSensors, isComponentHasCameraSensors, isMachineHasOtherSensors]);

  useEffect(() => {
    if (selectedStatusChanged.health_status_changed) {
      const changedComponents = components.filter((component: any) => component.health_status_changed === true);
      changedComponents[0] = {
        ...changedComponents[0],
        selected: true,
      };
      setComponents(changedComponents);
      setSelectedComponent(changedComponents[0]);
    }
    if (selectedStatusChanged.not_monitored) {
      const changedComponents = components.filter(
        (component: any) => component.health_status === HEALTH_STATUS.NOT_AVAILABLE
      );
      changedComponents[0] = {
        ...changedComponents[0],
        selected: true,
      };
      setComponents(changedComponents);
      setSelectedComponent(changedComponents[0]);
    } else {
      setComponents(allComponents);
      setSelectedComponent(allComponents[0]);
    }
  }, [selectedStatusChanged]);

  useEffect(() => {
    group && setActiveSensorsDataType(group);
  }, [tabIndex, group]);

  useEffect(() => {
    if (diagnostics) {
      setDiagnosticsData(diagnostics);
    }
  }, [diagnostics]);

  useEffect(() => {
    if (trendDiagnostics) {
      setDiagnosticsData(trendDiagnostics);
    }
  }, [trendDiagnostics]);

  const importOrder = [
    HEALTH_STATUS.CRITICAL,
    HEALTH_STATUS.ALARM,
    HEALTH_STATUS.MONITOR,
    HEALTH_STATUS.HEALTHY,
    HEALTH_STATUS.NOT_AVAILABLE,
  ];

  const sortByObject = importOrder.reduce((obj: any, item, index) => {
    return {
      ...obj,
      [item]: index,
    };
  }, {});

  const onAllComponentsClick = () => {
    const updatedComponents = components.map((component: any) => ({
      ...component,
      selected: false,
    }));
    navigate(generatePath(locations.protected.sites.components, { siteId, machineId }));
    setComponents(updatedComponents);
    setSelectedComponent(null);
    setActiveTabIndex(0);
    setSelectedStatuses({
      ...INITIAL_FILTERS,
      all: true,
    });
  };

  const onComponentListItemClick = (selectedComponent: any) => {
    const updatedComponents = components.map((component: any) => {
      return {
        ...component,
        selected: (() => {
          const isSelected = component.component_id === selectedComponent.component_id;
          if (isSelected) {
            setSelectedComponentId(component.component_id);
          }
          return isSelected;
        })(),
      };
    });
    setSelectedComponent(selectedComponent);
    setComponents(updatedComponents);
    navigate(
      `${generatePath(locations.protected.sites.specificComponent, {
        siteId,
        machineId,
        componentId: selectedComponent.component_id,
      })}?${searchParams.toString()}`
    );
  };

  const selectSpecificDate = (value: string) => {
    const selectedDate = value[0];
    const clonedDiagnostics = [...diagnosticsData];
    const selectedValue = clonedDiagnostics.filter((item) => item[0] === selectedDate).flat();
    const selectedObject = selectedValue[1];

    const selectedDateDiagnostics = selectedObject?.assets
      .map((asset: any) => {
        return asset.components
          .filter((component: any) => {
            if (selectedComponent) {
              if (component.component_id === selectedComponent.component_id) {
                return component;
              }
            } else {
              return component;
            }
          })
          .map((component: any) => {
            return component.diagnostics
              .map((diagnostic: any) => {
                return {
                  component_name: component.component_name,
                  component_id: component.component_id,
                  ...diagnostic,
                };
              })
              .flat();
          })
          .flat();
      })
      .flat();

    setSelectedDiagnostics(selectedDateDiagnostics);
  };

  const onPointClick = (value: any) => {
    setActiveChartPoint(value);
    selectSpecificDate(value);
  };

  const sortedSensorsByUptime = asset?.sensors_health_status.length
    ? [...asset.sensors_health_status]?.sort((a: any, b: any) => {
        if (a.last_uptime > b.last_uptime) return -1;
        if (a.last_uptime < b.last_uptime) return 1;
        return 0;
      })
    : null;

  const groupedSensorsByComponent = sortedSensorsByUptime?.reduce((result: any, item) => {
    const key = item.component_id;
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(item);
    return result;
  }, {});

  const getComponentLastUptime = () => {
    let lastUptime = null;
    if (selectedComponent && groupedSensorsByComponent) {
      const component = groupedSensorsByComponent[selectedComponent.component_id];
      if (component && component?.length) {
        lastUptime = component[0].last_uptime;
      }
    }
    return lastUptime;
  };

  const componentLastUptime = getComponentLastUptime();

  useEffect(() => {
    if (diagnosticsData.length && !isLoading) {
      const soonerDiagnosticsDate = new Date(diagnosticsData[0][0]);
      const soonerDiagnosticsDateMonthIndex = getMonth(soonerDiagnosticsDate);
      const fromDateMonthIndex = getMonth(fromDate);
      const soonerDiagnosticsDateYear = getYear(soonerDiagnosticsDate);
      const fromDateYear = getYear(fromDate);

      if (fromDateYear < soonerDiagnosticsDateYear || fromDateMonthIndex < soonerDiagnosticsDateMonthIndex) {
        setFromDate(new Date(soonerDiagnosticsDate));
      }

      const components = diagnosticsData[diagnosticsData.length - 1][1].assets
        .map((asset: any) => {
          return asset.components
            .flat()
            .sort((a: any, b: any) => sortByObject[a.health_status] - sortByObject[b.health_status])
            .map((component: any) => {
              return {
                ...component,
              };
            });
        })
        .flat();

      const componentWithSelectedId = components.filter(
        (component: any) => component.component_id === selectedComponentId
      );

      const enrichedComponents = (() => {
        if (componentWithSelectedId.length) {
          return components.map((component: any) => {
            return {
              ...component,
              selected: component.component_id === selectedComponentId,
            };
          });
        } else {
          return components.map((component: any, index: number) => {
            return {
              ...component,
              selected: index === 0,
            };
          });
        }
      })();

      if (componentWithSelectedId.length) {
        setSelectedComponent(componentWithSelectedId[0]);
      } else {
        setSelectedComponent(components[0]);
      }

      setFilteredDiagnostics(diagnosticsData);
      setComponents(enrichedComponents);
      setAllComponents(enrichedComponents);

      navigate(generatePath(locations.protected.sites.components, { siteId, machineId }));
    }
  }, [diagnosticsData, machineId]);

  useEffect(() => {
    if (components) {
      for (const component of components) {
        const queryParams = {
          site_id: siteId,
          asset_name: asset?.asset_name,
          component_id: component.component_id,
        };

        fetchSensorsRMS(
          {
            ...queryParams,
          },
          true
        );
        fetchSpectralAccelerationData({ ...queryParams }, true);
        fetchSpectralVelocityData({ ...queryParams }, true);
      }
    }
  }, [components]);

  useEffect(() => {
    if (diagnosticsData.length) {
      const latestDiagnosticDate = diagnosticsData[diagnosticsData.length - 1];

      setFilteredDiagnostics(diagnosticsData);
      setActiveChartPoint([
        latestDiagnosticDate[0],
        healthStatusToNumberMap[latestDiagnosticDate[1].assets[0].health_status],
      ]);
      selectSpecificDate(latestDiagnosticDate);
    }
  }, [diagnosticsData]);

  useEffect(() => {
    if (diagnosticsData.length) {
      const latestDiagnosticDate = diagnosticsData[diagnosticsData.length - 1];

      if (selectedComponent) {
        const componentDiagnostics = getComponentDiagnostics(diagnosticsData, selectedComponent);
        const activeComponentDiagnostics = componentDiagnostics
          .filter((diagnostic: any) => activeChartPoint[0] === diagnostic[0])
          .flat()[1];

        if (
          activeChartPoint[0] !==
            selectedComponent.diagnostics[selectedComponent.diagnostics.length - 1].diagnostic_created_at &&
          activeComponentDiagnostics.length
        ) {
          setActiveChartPoint([
            activeChartPoint[0],
            healthStatusToNumberMap[activeComponentDiagnostics[0].health_status],
          ]);
        } else {
          setActiveChartPoint([
            selectedComponent.diagnostics[selectedComponent.diagnostics.length - 1].diagnostic_created_at,
            healthStatusToNumberMap[selectedComponent.health_status],
          ]);
        }
      } else {
        setActiveChartPoint([
          latestDiagnosticDate[0],
          healthStatusToNumberMap[latestDiagnosticDate[1].assets[0].health_status],
        ]);
      }
    }
  }, [selectedComponent]);

  const getDiagnostics = () => {
    const fromDateISO = format(fromDate, diagnosticsDateFormat);
    const toDateISO = format(toDate, diagnosticsDateFormat);

    if (siteId && machineId) {
      fetchDiagnostics(
        {
          site_id: siteId,
          assets_ids: machineId,
          from_date: fromDateISO,
          to_date: toDateISO,
        },
        true
      )
        .unwrap()
        .catch((e) => {
          Sentry.captureException(e);
          toast({
            status: TOAST_STATUS.Error,
            title: `Failed to load information for machine: ${asset?.asset_ui_name}`,
            description: t('general.errors.communication'),
          });
        });
    }
  };

  useEffect(() => {
    getDiagnostics();
  }, [fromDate, toDate, siteId, machineId]);

  useEffect(() => {
    setFromDate(defaultFromDateValue);
    setToDate(defaultToDateValue);
    setPageOpened(false);
    setTimeout(() => setPageOpened(true), 100);
  }, [machineId]);

  useEffect(() => {
    if (diagnosticsData.length) {
      const fromDateISO = format(fromDate, diagnosticsDateFormat);
      const toDateISO = format(toDate, diagnosticsDateFormat);

      if (siteId && machineId) {
        fetchTrendDiagnostics(
          {
            site_id: siteId,
            assets_ids: machineId,
            from_date: fromDateISO,
            to_date: toDateISO,
          },
          true
        )
          .unwrap()
          .catch((e) => {
            Sentry.captureException(e);
            toast({
              status: TOAST_STATUS.Error,
              title: `Failed to load information for machine: ${asset?.asset_ui_name}`,
              description: t('general.errors.communication'),
            });
          });
      }

      const filtered = diagnosticsData.filter((item: any) =>
        isDateInRange(item[0], new Date(fromDateISO), new Date(toDateISO))
      );

      setFilteredDiagnostics(filtered);
    }
  }, [fromDate, toDate]);

  const selectedDiagnosticsFiltered = !selectedStatuses.all
    ? selectedDiagnostics?.filter((item: any) => item.health_status === selectedStatusesKeys[0])
    : selectedDiagnostics;

  const onTabChange = (index: number) => {
    setSearchParams((params) => {
      return {
        ...params,
        tabIndex: index,
      };
    });
    setRevision(revision + 1);
    setActiveTabIndex(index);
  };

  useEffect(() => {
    setRevision(revision + 1);
  }, [activeSensorsDataType]);

  useEffect(() => {
    if (componentId) {
      const component = components.filter((component: any) => component.component_id === componentId)[0];
      const updatedComponents = components.map((component: any, index: number) => {
        return {
          ...component,
          selected: component.component_id === componentId,
        };
      });
      setComponents(updatedComponents);
      setSelectedComponent(component);
      setRevision(revision + 1);
    }
  }, [componentId]);

  const reversedData = [...diagnosticsData].reverse();

  const today = new Date();
  const twentyDaysAgo = new Date(today);
  twentyDaysAgo.setDate(twentyDaysAgo.getDate() - 20);

  const filteredDiagnostics20: any = reversedData.filter((entry) => {
    const entryDate = new Date(entry[0]);
    return entryDate >= twentyDaysAgo;
  });

  const latest20: any = filteredDiagnostics20.map((entry: any) => [entry[0], entry[1]]);

  const latest20Diagnostics = latest20.flatMap((entry: any) =>
    entry[1].assets.flatMap((asset: any) =>
      asset.components.flatMap((component: any) =>
        component.diagnostics.filter(
          (diagnostic: any) =>
            diagnostic.sources_info.some(
              (source: any) =>
                source.source_type.toLowerCase() === SENSOR_TYPE.Camera && source.evidence_resource_id !== null
            ) &&
            (diagnostic.fault_type.toLowerCase().includes(FAILURE_NAME.BeltShift) ||
              diagnostic.fault_type.toLowerCase() === FAILURE_NAME.GapSize)
        )
      )
    )
  );

  const hasCamera = selectedComponent?.diagnostics[0].sources_info.some(
    (item: any) => item.source_type.toLowerCase() === SENSORS_DATA_TYPE.Camera
  );
  function doesComponentExist(componentName: string, latestDiagnosticsEntry: any[]): boolean {
    return latestDiagnosticsEntry.some((component: any) => component.component_name === componentName);
  }
  const latestDiagnosticsEntry = diagnosticsData[diagnosticsData.length - 1]?.flat()[1].assets[0].components;

  const filterByCameraSensor = (components: any) => {
    const filteredComponents = components.filter((component: any) => {
      return component.diagnostics.some((diagnostic: any) => {
        return diagnostic.sources_info.some((source: any) => source.source_type?.toLowerCase() === SENSOR_TYPE.Camera);
      });
    });
    return filteredComponents;
  };

  return (
    <Suspense>
      <Header>
        <ErrorBoundary FallbackComponent={Fallback}>
          {site && asset && selectedDiagnostics ? (
            <Toolbar
              siteId={siteId}
              siteName={site.site_name}
              machineName={asset.asset_ui_name}
              machineId={asset.asset_name}
              asset={asset}
              tabIndex={activeTabIndex}
              machines={assets}
              assetHealthStatus={asset.health_status}
              latestDiagnosticDate={asset.latest_diagnostic_created_at}
              amountByStatus={getAmountByStatus([...selectedDiagnostics])}
              selectedStatuses={selectedStatuses}
              setSelectedStatuses={setSelectedStatuses}
              selectedSensors={selectedSensors}
              setSelectedSensors={setSelectedSensors}
              machineSensorModal={
                <MachineSensorsModal
                  sensors={asset.sensors_health_status}
                  title={asset.asset_ui_name}
                  siteId={siteId!}
                  assetId={asset.asset_id}
                />
              }
              isTopFixed
              amountByStatusChanged={getTotalComponentWithStatusChanged([...components], siteLastVerified, asset)}
              selectedStatusChanged={selectedStatusChanged}
              setSelectedStatusChanged={setSelectedStatusChanged}
              selectedComponent={selectedComponent}
              setSelectedComponent={setSelectedComponent}
              components={components}
              setComponents={setComponents}
              componentId={componentId}
              amountByCamera={getComponentAmountByCamera([...components])}
            />
          ) : null}
        </ErrorBoundary>
      </Header>

      <Box {...containerProps}>
        <Show below="xl">
          <ErrorBoundary FallbackComponent={Fallback}>
            <ComponentsMobileMenu
              selectedComponent={selectedComponent}
              onAllComponentsClick={onAllComponentsClick}
              components={components}
              onComponentListItemClick={onComponentListItemClick}
            />
          </ErrorBoundary>
        </Show>

        {!isLoading || !isFetching ? (
          <Fade
            in={(!isLoading || !isFetching) && isPageOpened}
            transition={{ enter: { delay: 0 }, exit: { delay: 0 } }}
            unmountOnExit
          >
            <Tabs index={activeTabIndex} variant="unstyled" bgColor="#fff" onChange={onTabChange} isLazy mb={0}>
              <ErrorBoundary FallbackComponent={Fallback}>
                <HStack
                  justifyContent="space-between"
                  position="sticky"
                  top={{ base: '6rem', xl: '3.5rem', '1.5xl': '3.5rem' }}
                  bgColor="#fff"
                  pb="24px"
                  pt="32px"
                  zIndex={1}
                >
                  <Hide below="xl">
                    <ComponentTabList
                      selectedComponent={selectedComponent}
                      isComponentHasCameraSensors={isComponentHasCameraSensors}
                      hasCamera={hasCamera}
                    />
                    {activeTabIndex === 1 && (isMachineHasOtherSensors || isComponentHasVibrationSensors) && (
                      <SensorsDataDropdown
                        activeSensorsDataType={activeSensorsDataType}
                        setActiveSensorsDataType={setActiveSensorsDataType}
                        isComponentHasVibrationSensors={isComponentHasVibrationSensors}
                        isMachineHasOtherSensors={isMachineHasOtherSensors}
                      />
                    )}
                  </Hide>
                </HStack>
              </ErrorBoundary>

              <HStack alignItems="flex-start" spacing={{ base: 0, xl: 4, '1.5xl': 4 }}>
                <Box position="sticky" top={{ base: '6rem', xl: '9.9375rem', '1.5xl': '9.9375rem' }}>
                  <Hide below="xl">
                    <ErrorBoundary FallbackComponent={Fallback}>
                      {isLoading ? null : (
                        <Box>
                          <ComponentsSidebar
                            isLoading={isLoadingTrendDiagnostics || isFetchingTrendDiagnostics}
                            asset={asset}
                            selectedComponent={selectedComponent}
                            onAllComponentsClick={onAllComponentsClick}
                            components={selectedSensors === 'all' ? components : filterByCameraSensor(components)}
                            element={(component: any, index: number) => (
                              <ComponentSidebarItem
                                key={index}
                                onClick={() => onComponentListItemClick(component)}
                                component={component}
                                componentExists={doesComponentExist(component.component_name, latestDiagnosticsEntry)}
                                siteLastVerified={siteLastVerified}
                                assetHealthStatus={assetHealthStatus}
                                assetLatestDiagnosticDate={assetLatestDiagnosticDate}
                              />
                            )}
                          />
                        </Box>
                      )}
                    </ErrorBoundary>
                  </Hide>
                </Box>

                <Box w="full">
                  <TabPanels h="full" w="full" bgColor="transparent">
                    <TabPanel css={tabPanelStyle} pt={0}>
                      <ErrorBoundary FallbackComponent={Fallback}>
                        <ComponentDiagnostics
                          asset={asset}
                          selectedComponent={selectedComponent}
                          filteredDiagnostics={filteredDiagnostics}
                          activeChartPoint={activeChartPoint}
                          onPointClick={onPointClick}
                          fromDate={fromDate}
                          toDate={toDate}
                          setFromDate={setFromDate}
                          setToDate={setToDate}
                          isLoading={isLoadingTrendDiagnostics || isFetchingTrendDiagnostics}
                        />
                        <ComponentFailuresList selectedDiagnosticsFiltered={selectedDiagnosticsFiltered} />
                      </ErrorBoundary>
                    </TabPanel>

                    <TabPanel pt={0} css={tabPanelStyle}>
                      <ErrorBoundary FallbackComponent={Fallback}>
                        {selectedComponent ? (
                          <Box>
                            <SensorsTabs
                              component={selectedComponent}
                              activeSensorsDataType={activeSensorsDataType}
                              revision={revision}
                              siteId={siteId}
                              asset={asset}
                              componentLastUptime={componentLastUptime}
                              selectedComponent={selectedComponent}
                              isComponentHasCameraSensors={isComponentHasCameraSensors}
                              latest20Diagnostics={latest20Diagnostics}
                            />
                          </Box>
                        ) : null}
                      </ErrorBoundary>
                    </TabPanel>

                    <TabPanel pt={0} css={tabPanelStyle}>
                      <ErrorBoundary FallbackComponent={Fallback}>
                        {selectedComponent ? (
                          <VisualAI
                            asset={asset}
                            component={selectedComponent}
                            diagnosticsData={diagnosticsData}
                            componentLastUptime={componentLastUptime}
                            selectedComponent={selectedComponent}
                            revision={revision}
                          />
                        ) : null}
                      </ErrorBoundary>
                    </TabPanel>

                    <TabPanel pt={0} css={tabPanelStyle}>
                      <ErrorBoundary FallbackComponent={Fallback}>
                        <MaintenanceTable />
                      </ErrorBoundary>
                    </TabPanel>
                  </TabPanels>
                </Box>
              </HStack>
            </Tabs>
          </Fade>
        ) : (
          <Loading />
        )}
      </Box>
    </Suspense>
  );
};

const containerProps = {
  w: 'full',
  pt: { base: '8rem', xl: '3.5rem', '1.5xl': '3.5rem' },
  pl: { base: 0, xl: '1rem', '1.5xl': '2rem' },
  pb: { base: 4, xl: '1rem', '1xl': '2rem' },
  pr: { base: 0, xl: '1rem', '1.5xl': '2rem' },
};

const tabPanelStyle = css`
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
`;
