import { VStack } from '@chakra-ui/react';
import React, { forwardRef, ReactNode, Ref } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHoveredTire, setSelectedTire } from 'src/app/slices/mobileAssetDetailedDiagnosticsSlice';
import { RootState } from 'src/app/store';
import { cardContainerStyle } from './AssetStatusCardContainer.styles';

type AssetStatusCardProps = {
  children: ReactNode;
  currentElId: string;
};

export const AssetStatusCardContainer = forwardRef(({ children, currentElId }: AssetStatusCardProps, ref) => {
  const { selectedTire, hoveredTire } = useSelector((state: RootState) => state.mobileAssetDetailedDiagnostics);
  const isSelected = selectedTire === currentElId;
  const isHovered = hoveredTire === currentElId;
  const dispatch = useDispatch();
  return (
    <VStack
      onClick={() => dispatch(setSelectedTire(currentElId))}
      onMouseEnter={() => dispatch(setHoveredTire(currentElId))}
      onMouseLeave={() => dispatch(setHoveredTire(null))}
      ref={ref as Ref<HTMLDivElement>}
      sx={{
        ...cardContainerStyle,
        boxShadow: `${isSelected ? '0px 2px 10px #ccc' : ''}`,
        transform: `${isHovered ? 'scale(1.05)' : ''}`,
      }}
    >
      {children}
    </VStack>
  );
});

AssetStatusCardContainer.displayName = 'AssetStatusCardContainer';
