import { createApi, retry } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithStatusCheck } from 'src/app/baseQueryWithStatusCheck';

const baseQuery = retry(baseQueryWithStatusCheck, { maxRetries: 2 });

export const mobileFleetSensorDataApi = createApi({
  reducerPath: 'mobileFleetSensorDataApi',
  baseQuery,
  tagTypes: ['MobileFleetSensorData'],
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getMobileFleetSensorData: builder.query<any, any>({
      query: ({ site_id, filename }) => {
        const searchParams = new URLSearchParams();

        if (site_id) {
          searchParams.append('site_id', site_id);
        }

        if (filename) {
          searchParams.append('filename', filename);
        }

        return { url: `sensors_data/mobile?${searchParams.toString()}` };
      },
      providesTags: [{ type: 'MobileFleetSensorData' }],
    }),
  }),
});

export const { useLazyGetMobileFleetSensorDataQuery } = mobileFleetSensorDataApi;
