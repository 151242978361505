import React from 'react';
import { Box, Circle, HStack } from '@chakra-ui/react';
import { colors } from 'src/theme/foundations/colors';
import { cardHeaderStyle } from './AssetStatusCardHeade.style';
import { useTireLifespan } from 'src/hooks/newFolderStructure';

type AssetStatusCardHeaderPropsType = {
  tireNumber: number;
  lifeSpan: { max: number; current: number };
};

export const AssetStatusCardHeader = ({ lifeSpan, tireNumber }: AssetStatusCardHeaderPropsType) => {
  const { max, current } = lifeSpan;
  const { status, lifespanRatio } = useTireLifespan(lifeSpan);
  return (
    <HStack sx={cardHeaderStyle} w="full">
      <HStack>
        <Circle background={colors.health[status as 'critical']} size="10px" />
        <Box>{`Tire ${tireNumber}`}</Box>
      </HStack>
      {max && max > 0 && <Box>{Math.round(100 * lifespanRatio) + '%'}</Box>}
    </HStack>
  );
};
