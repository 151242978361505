import React, { useCallback, useMemo } from 'react';
import { IconButton } from '@chakra-ui/react';
import { DMMinus, DMPlus } from 'src/components/Icons';
import { ZoomActionEnum } from 'src/types/newFolderStructure';
import { zoomBtnStyle } from './MapZoomButtons.styles';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';

export const MapZoomButtons = () => {
  const { mapInstance } = useSelector((state: RootState) => state.siteMap);
  const handleZoom = useCallback(
    (action: ZoomActionEnum) => {
      if (mapInstance) {
        action === ZoomActionEnum.ZOOM_IN
          ? mapInstance.setZoom(mapInstance.getZoom()! + 1)
          : mapInstance.setZoom(mapInstance.getZoom()! - 1);
      }
    },
    [mapInstance]
  );

  const zoomButtons = useMemo(
    () => [
      {
        label: ZoomActionEnum.ZOOM_IN,
        icon: <DMPlus />,
        onClick: () => handleZoom(ZoomActionEnum.ZOOM_IN),
      },
      {
        label: ZoomActionEnum.ZOOM_OUT,
        icon: <DMMinus />,
        onClick: () => handleZoom(ZoomActionEnum.ZOOM_OUT),
      },
    ],
    [mapInstance]
  );

  return (
    <>
      {zoomButtons.map((zoomButton) => (
        <IconButton
          key={zoomButton.label}
          sx={zoomBtnStyle}
          icon={zoomButton.icon}
          onClick={zoomButton.onClick}
          aria-label={zoomButton.label}
        />
      ))}
    </>
  );
};
