import { MapViewLayerEnum } from 'src/types/newFolderStructure';
import { speedMapColors, heatMapColors } from '../../common';

export const MapLineIndexColors = {
  [MapViewLayerEnum.SPEED]: speedMapColors,
  [MapViewLayerEnum.TIRE_WEAR_HEATMAP]: heatMapColors,
};

export const MapLineIndexLabels = {
  [MapViewLayerEnum.SPEED]: {
    min: '0 km/h',
    max: '50+ km/h',
  },
  [MapViewLayerEnum.TIRE_WEAR_HEATMAP]: {
    min: 'Severe Tire Wear',
    max: 'Mild Tire Wear',
  },
};
