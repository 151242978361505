import { createSlice } from '@reduxjs/toolkit';
import { resetMap } from './siteMapSlice';

type MobileAssetDetailedDiagnosticsState = {
  selectedTire: null | string;
  hoveredTire: null | string;
};

const initialState: MobileAssetDetailedDiagnosticsState = {
  selectedTire: null,
  hoveredTire: null,
};

const mobileAssetDetailedDiagnosticsSlice = createSlice({
  initialState,
  name: 'mobileAssetDetailedDiagnostics',
  reducers: {
    setSelectedTire(state, action) {
      state.selectedTire = state.selectedTire === action.payload ? null : action.payload;
    },
    setHoveredTire(state, action) {
      if (state.hoveredTire === action.payload) return;
      state.hoveredTire = action.payload;
    },
    resetTires() {
      return initialState;
    },
  },
});

export const { setSelectedTire, setHoveredTire, resetTires } = mobileAssetDetailedDiagnosticsSlice.actions;
export const mobileAssetDetailedDiagnosticsSliceReducer = mobileAssetDetailedDiagnosticsSlice.reducer;
