import React from 'react';
import { Button } from '@chakra-ui/react';
import { GiRoad, GiSpeedometer } from 'react-icons/gi';
import { MapViewLayerEnum } from 'src/types/newFolderStructure';
import { buttonStyle } from './MapDataLayerSelector.styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { setSelectedMapViewLayer } from 'src/app/slices/siteMapSlice';
import { PiTire } from 'react-icons/pi';
export const MapDataLayerSelector = () => {
  const dispatch = useDispatch();
  const { selectedMapViewLayer } = useSelector((state: RootState) => state.siteMap);
  const icon =
    selectedMapViewLayer === MapViewLayerEnum.TIRE_WEAR_HEATMAP ? <PiTire size={24} /> : <GiSpeedometer size={24} />;
  return (
    <Button
      sx={{ ...buttonStyle }}
      leftIcon={icon}
      onClick={() =>
        dispatch(
          setSelectedMapViewLayer(
            selectedMapViewLayer === MapViewLayerEnum.TIRE_WEAR_HEATMAP
              ? MapViewLayerEnum.SPEED
              : MapViewLayerEnum.TIRE_WEAR_HEATMAP
          )
        )
      }
      aria-label={selectedMapViewLayer}
    >
      {selectedMapViewLayer}
    </Button>
  );
};
