import { useEffect, useState } from 'react';

export const useContainerWidth = (containerRef: React.RefObject<HTMLDivElement>, defaultWidth = 250) => {
  const [containerWidth, setContainerWidth] = useState<number>(defaultWidth);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.clientWidth);
      }
    };

    // Update width on mount
    const timeoutPointer = setTimeout(() => updateWidth(), 100);

    // Add event listener for window resize
    window.addEventListener('resize', updateWidth);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', updateWidth);
      clearTimeout(timeoutPointer);
    };
  }, []);

  return containerWidth;
};
