import { format } from 'date-fns';
import {
  TASK_DATE_FORMAT,
  TASK_RECTANGLE_ACTIVE_COLOR,
  TASK_RECTANGLE_COLOR,
} from 'src/features/maintenance-data/maintenance-chart/const';
import {
  MaintenanceDataRaw,
  MaintenanceTask,
  MaintenanceTaskRaw,
  MaintenanceTasks,
} from 'src/features/maintenance-data/maintenance-chart/types';

const initialGrid = {
  left: '4px',
  right: '4px',
  top: '6px',
  bottom: '6px',
};

export const getOption = (
  tasks: MaintenanceTasks,
  dataZoomParams: Record<string, number>,
  isDiagnosticsContext: boolean,
  xAxisMin?: string,
  xAxisMax?: string,
  grid?: Record<string, string>
) => {
  return {
    grid: grid || initialGrid,
    xAxis: {
      show: false,
      type: 'time',
      min: xAxisMin || undefined,
      max: xAxisMax || undefined,
    },
    lazyUpdate: true,
    yAxis: {
      show: false,
      inverse: true,
      type: 'category',
      data: ['Category 1'],
    },
    dataZoom: isDiagnosticsContext
      ? [
          {
            type: 'inside',
            filterMode: 'none',
            ...dataZoomParams,
          },
        ]
      : undefined,
    series: [
      {
        type: 'custom',
        renderItem: (params: any, api: any) => {
          const categoryIndex = api.value(0);
          const startTime = api.coord([api.value(1), categoryIndex]);
          const endTime = api.coord([api.value(2), categoryIndex]);
          const height = api.size([0, 1])[1] * 0.4;

          return {
            type: 'rect',
            shape: {
              x: startTime[0],
              y: startTime[1] - height / 2,
              width: startTime[0] === endTime[0] ? 6 : endTime[0] - startTime[0],
              height: height,
              r: 10,
            },
            style: api.style({
              fill: api.value(4),
            }),
          };
        },
        itemStyle: {
          borderColor: '#01152b',
          borderWidth: 1,
        },
        encode: {
          x: [1, 2],
          y: 0,
        },
        data: tasks?.map((task: MaintenanceTask) => [
          task.categoryIndex,
          task.startTime,
          task.endTime,
          task.taskName,
          task.color,
          task.details,
        ]),
      },
    ],
  };
};

export const extractTasks = (data: MaintenanceDataRaw): Array<MaintenanceTaskRaw> => {
  const innerObjects: Array<MaintenanceTaskRaw> = [];

  for (const key in data) {
    innerObjects.push(...data[key]);
  }

  return innerObjects;
};

export const enrichTasks = (tasks: Array<MaintenanceTaskRaw>) => {
  return tasks.map((task: MaintenanceTaskRaw, index: number) => {
    return {
      categoryIndex: 0,
      startTime: format(new Date(task.actualStartDate), TASK_DATE_FORMAT),
      endTime: format(new Date(task.actualEndDate), TASK_DATE_FORMAT),
      taskName: task.workOrderNumber,
      color: TASK_RECTANGLE_COLOR,
      details: {
        ...task,
      },
    };
  });
};

export const updateTaskColors = (tasks: MaintenanceTasks, clickedTaskIndex: number) => {
  return tasks.map((task, index) => {
    if (index === clickedTaskIndex) {
      return {
        ...task,
        color: task.color === TASK_RECTANGLE_ACTIVE_COLOR ? TASK_RECTANGLE_COLOR : TASK_RECTANGLE_ACTIVE_COLOR,
      };
    }

    return {
      ...task,
      color: TASK_RECTANGLE_COLOR,
    };
  });
};

const parseDate = (dateStr: string): Date => new Date(dateStr);

const tasksOverlap = (task1: MaintenanceTask, task2: MaintenanceTask): boolean => {
  return parseDate(task1.endTime) > parseDate(task2.startTime) && parseDate(task1.startTime) < parseDate(task2.endTime);
};

const isWithinDateRange = (task: MaintenanceTask, startDate: string, endDate: string): boolean => {
  const taskStart = parseDate(task.startTime).getTime();
  const taskEnd = parseDate(task.endTime).getTime();
  const rangeStart = parseDate(startDate).getTime();
  const rangeEnd = parseDate(endDate).getTime();

  return taskStart <= rangeEnd && taskEnd >= rangeStart;
};

export const organizeGanttTasks = (
  tasks: MaintenanceTask[],
  startDate: string,
  endDate: string
): MaintenanceTask[][] => {
  const filteredTasks = tasks.filter((task) => isWithinDateRange(task, startDate, endDate));

  filteredTasks.sort((a, b) => parseDate(a.startTime).getTime() - parseDate(b.startTime).getTime());

  const organizedTasks: MaintenanceTask[][] = [[]];

  for (const task of filteredTasks) {
    let placed = false;

    for (let index = 0; index < 3; index++) {
      if (index >= organizedTasks[organizedTasks.length - 1].length) {
        organizedTasks[organizedTasks.length - 1].push({ ...task, categoryIndex: index });
        placed = true;
        break;
      }

      const existingTasksInRow = organizedTasks[organizedTasks.length - 1].filter((t) => t.categoryIndex === index);
      const hasOverlap = existingTasksInRow.some((existingTask) => tasksOverlap(existingTask, task));

      if (!hasOverlap) {
        organizedTasks[organizedTasks.length - 1].push({ ...task, categoryIndex: index });
        placed = true;
        break;
      }
    }

    if (!placed) {
      organizedTasks.push([{ ...task, categoryIndex: 0 }]);
    }
  }

  return organizedTasks;
};
