export enum MapViewTypeEnum {
  HEAT_MAP = 'heat',
  PATH_MAP = 'line',
}

export enum MapViewLayerEnum {
  SPEED = 'speed map',
  TIRE_WEAR_HEATMAP = 'tire wear heatmap',
}

export enum ZoomActionEnum {
  ZOOM_OUT = 'zoomOut',
  ZOOM_IN = 'zoomIn',
}
