import React from 'react';
import { HStack, VStack } from '@chakra-ui/react';
import CardPane from './CardPane/CardPane';
import { paneStyle } from './common/CardPane.style';
import { AssetStatusGraphics } from './AssetStatusGraphics';
import { tiresData } from './mockData';
import { mobileAssetTireHealthContainerStyle, mobileAssetTireHealthHeaderStyle } from './MobileAssetTiresHealth.styles';

export const MobileAssetTiresHealth = () => {
  const leftTires = tiresData.filter((tire) => tire.id?.toString().toLocaleLowerCase()?.includes('left'));
  const rightTires = tiresData.filter((tire) => !tire.id?.toString().toLocaleLowerCase()?.includes('left'));

  return (
    <HStack sx={mobileAssetTireHealthContainerStyle}>
      <CardPane data={leftTires} />
      <VStack sx={{ ...paneStyle, justifyContent: 'center', gap: 20 }}>
        <h2 style={mobileAssetTireHealthHeaderStyle}>Tire Health</h2>
        <AssetStatusGraphics />
      </VStack>
      <CardPane data={rightTires} />
    </HStack>
  );
};
