import React from 'react';
import ReactECharts from 'echarts-for-react';

type HorizontalProgressBarPropsType = {
  progressValue: number;
  containerWidth: number;
};

export const HorizontalProgressBar = ({
  progressValue,
  containerWidth: currentContainerW,
}: HorizontalProgressBarPropsType) => {
  //   const progressValue = progressValue; // Current progress value (in percentage)
  const containerWidth = currentContainerW - 5; // Fixed width of the containe + negative value for padding
  // Calculate the pixel position of the marker based on progress value
  const markerX = (progressValue / 100) * containerWidth;

  const option = {
    backgroundColor: '#fff', // Background color for the chart area
    padding: '10px',
    xAxis: {
      max: 100, // Set maximum value to 100%
      show: false, // Hide x-axis labels and ticks
    },
    yAxis: {
      type: 'category',
      data: ['Progress'],
      show: false, // Hide y-axis labels and ticks
    },
    series: [
      {
        type: 'bar',
        data: [100], // Full width progress bar (fixed at 100%)
        barWidth: 6, // Fixed thickness of the bar
        itemStyle: {
          normal: {
            barBorderRadius: [10, 10, 10, 10], // Rounded corners
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 0,
              colorStops: [
                { offset: 0, color: 'rgba(17, 151, 93, 0.2)' }, // 49.96% position for the greenish transparent color
                { offset: 0.4996, color: '#F1BF42' }, // 0% position for red
                { offset: 0.9988, color: '#CC0000' }, // 99.88% position for yellow
              ],
            },
          },
        },
        z: 10, // Ensure the bar has a high z-index to bring it to the front
      },
    ],
    graphic: [
      {
        type: 'line', // Use a line graphic to draw the main marker line
        shape: {
          x1: markerX, // Use pixel value for the line's x position
          y1: 6, // Start from the top of the chart
          x2: markerX, // Same as x1 to make it a vertical line
          y2: 29, // Extend line lower for better visibility
        },
        style: {
          stroke: '#000', // Main line color (black)
          lineWidth: 2.4, // Line thickness for visibility
        },
        z: 20, // Higher z-index to ensure it’s drawn over the bar
      },
      {
        type: 'line', // Left border line
        shape: {
          x1: markerX - 3, // Position slightly to the left
          y1: 0, // Same start point
          x2: markerX - 3, // Same as x1 to make it a vertical line
          y2: 40, // Same end point
        },
        style: {
          stroke: '#fff', // Border color (white)
          lineWidth: 3, // Border thickness (larger than the main line)
        },
        z: 19, // Lower z-index to be behind the main line
      },
      {
        type: 'line', // Right border line
        shape: {
          x1: markerX + 3, // Position slightly to the right
          y1: 0, // Same start point
          x2: markerX + 3, // Same as x1 to make it a vertical line
          y2: 40, // Same end point
        },
        style: {
          stroke: '#fff', // Border color (white)
          lineWidth: 3, // Border thickness (larger than the main line)
        },
        z: 19, // Lower z-index to be behind the main line
      },
    ],
    grid: {
      left: 10, // Add some space for the left border
      right: 10, // Add some space for the right border
      top: 10, // Add some space for the top border
      bottom: 10, // Add some space for the bottom border
      containLabel: false,
      backgroundColor: '#fff', // Chart background color
      borderColor: '#fff', // Set the border color (white)
      borderWidth: 2, // Set the border width
    },
  };

  return (
    <div
      style={{
        width: `${containerWidth}px`,
        height: '40px',
        border: '2px solid white',
        borderRadius: '5px',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <ReactECharts option={option} style={{ width: '100%', height: '100%' }} />
    </div>
  );
};
