import React from 'react';
import { Box, StyleProps } from '@chakra-ui/react';
import { AssetFaultVariant } from 'src/types/assets';

type AssetFaultTypeProps = {
  faultType: string;
  variant: AssetFaultVariant;
  styles?: StyleProps;
};

export const AssetFaultType = ({ faultType, variant, styles }: AssetFaultTypeProps) => {
  return (
    <Box
      fontSize="14px"
      color="#fff"
      fontWeight={600}
      px="1rem"
      py="0.5rem"
      borderRadius="lg"
      backgroundColor={variant === 'critical' ? '#CC0000' : variant === 'alarm' ? '#FF891B' : '#f1bf42'}
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      {...styles}
    >
      {faultType}
    </Box>
  );
};
