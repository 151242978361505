import React from 'react';
import {
  CATTruckBottomInnerLeftTire,
  CATTruckBottomInnerRightTire,
  CATTruckBottomOutterLeftTire,
  CATTruckBottomOutterRightTire,
  CATTruckTopLeftTire,
  CATTruckTopRightTire,
} from 'src/assets/newFolderStructure/svg';
import {
  tagStyle,
  tiresBottomInnerOffestValue,
  tiresBottomOutterOffestValue,
  tiresBottomStyle,
  tiresStyle,
  tiresTopLeftOffestValue,
  tiresTopRightOffestValue,
  tiresTopStyle,
} from './tires-health-components-config.styles';

export const tiresComponents = [
  {
    id: 'topLeft',
    component: CATTruckTopLeftTire,
    sx: { ...tiresStyle, ...tiresTopStyle, left: tiresTopLeftOffestValue },
    tagSx: { ...tagStyle, top: `calc(${tiresTopStyle.top} - 8px)`, left: `calc(${tiresTopLeftOffestValue} - 7px)` },
  },
  {
    id: 'topRight',
    component: CATTruckTopRightTire,
    sx: { ...tiresStyle, ...tiresTopStyle, right: tiresTopRightOffestValue },
    tagSx: { ...tagStyle, top: `calc(${tiresTopStyle.top} - 8px)`, right: `calc(${tiresTopRightOffestValue} - 7px)` },
  },
  {
    id: 'bottomInnerRight',
    component: CATTruckBottomInnerRightTire,
    sx: { ...tiresStyle, ...tiresBottomStyle, right: tiresBottomInnerOffestValue },
    tagSx: {
      ...tagStyle,
      bottom: `calc(${tiresBottomStyle.bottom} - 10px)`,
      right: `calc(${tiresBottomInnerOffestValue} + 35px)`,
    },
  },
  {
    id: 'bottomOutterRight',
    component: CATTruckBottomOutterRightTire,
    sx: { ...tiresStyle, ...tiresBottomStyle, right: tiresBottomOutterOffestValue },
    tagSx: {
      ...tagStyle,
      bottom: `calc(${tiresBottomStyle.bottom} - 10px)`,
      right: `calc(${tiresBottomOutterOffestValue} - 7px)`,
    },
  },
  {
    id: 'bottomInnerLeft',
    component: CATTruckBottomInnerLeftTire,
    sx: { ...tiresStyle, ...tiresBottomStyle, left: tiresBottomInnerOffestValue },
    tagSx: {
      ...tagStyle,
      bottom: `calc(${tiresBottomStyle.bottom} - 10px)`,
      left: `calc(${tiresBottomOutterOffestValue}  + 85px)`,
    },
  },
  {
    id: 'bottomOutterLeft',
    component: CATTruckBottomOutterLeftTire,
    sx: { ...tiresStyle, ...tiresBottomStyle, left: tiresBottomOutterOffestValue },
    tagSx: {
      ...tagStyle,
      bottom: `calc(${tiresBottomStyle.bottom} - 10px)`,
      left: `calc(${tiresBottomOutterOffestValue} - 7px)`,
    },
  },
];
