import { VStack, Box, HStack, Button, IconButton } from '@chakra-ui/react';
import React, { useState } from 'react';
import { TbMapQuestion } from 'react-icons/tb';
import { containerStyle, titleStyle } from './MapIndex.styles';
import MapIndexItem from './MapIndexItem';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { MapViewLayerEnum } from 'src/types/newFolderStructure';
import { SPEED_MAP_INDEX, TIRE_WEAR_HEATMAP_MAP_INDEX } from 'src/constants/newFolderStructure/mobile-fleet/site-map';

export const MapIndex = () => {
  const { selectedMapViewLayer } = useSelector((state: RootState) => state.siteMap);
  const mapIndexData = selectedMapViewLayer === MapViewLayerEnum.SPEED ? SPEED_MAP_INDEX : TIRE_WEAR_HEATMAP_MAP_INDEX;
  const [show, setShow] = useState(false);

  if (!show)
    return <IconButton icon={<TbMapQuestion size={24} />} onClick={() => setShow(true)} aria-label={''} zIndex={3} />;

  return (
    <VStack sx={containerStyle} justifyItems={'flex-start'} alignContent={'center'} zIndex={3}>
      <HStack>
        <Box sx={titleStyle}>{selectedMapViewLayer} index</Box>
      </HStack>
      {mapIndexData.map((mapIndexItem) => (
        <MapIndexItem {...mapIndexItem} key={mapIndexItem.label} />
      ))}
      <Button
        variant={'ghost'}
        size={'sm'}
        alignSelf={'flex-end'}
        onClick={() => setShow(false)}
        sx={{ _hover: { opacity: 0.8 } }}
      >
        Close
      </Button>
    </VStack>
  );
};
