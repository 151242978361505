export const tiresData = [
  { id: 'topLeft', status: 'critical', tireNumber: 2, lifeSpan: { max: 5000, current: 4000 } },
  {
    id: 'topRight',
    status: 'critical',
    tireNumber: 1,
    lifeSpan: { max: 5000, current: 1500 },
  },
  { id: 'bottomInnerLeft', status: 'healthy', tireNumber: 5, lifeSpan: { max: 5000, current: 1500 } },
  { id: 'bottomOutterLeft', status: 'healthy', tireNumber: 6, lifeSpan: { max: 5000, current: 2500 } },
  { id: 'bottomInnerRight', status: 'healthy', tireNumber: 3, lifeSpan: { max: 5000, current: 800 } },
  { id: 'bottomOutterRight', status: 'healthy', tireNumber: 4, lifeSpan: { max: 5000, current: 400 } },
];
