import React, { Suspense, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Toolbar } from 'src/components/Toolbar';
import { useTranslation } from 'react-i18next';
import { Box, Fade, Hide, HStack, Image, TabPanel, TabPanels, Tabs, SimpleGrid, useToast } from '@chakra-ui/react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getSiteById } from 'src/app/queries';
import { format } from 'date-fns';
import { Header } from 'src/components/Header';
import { ComponentTabList } from 'src/pages/machines/components/ComponentTabList';
import { css } from '@emotion/react';
import Fallback from 'src/components/Fallback';
import { ErrorBoundary } from 'react-error-boundary';
import { MobileComponentsSidebar } from './mobile-diagnostics/MobileComponentsSidebar';
import { MobileComponentSidebarItem } from './mobile-diagnostics/MobileComponentSidebarItem';
import { MobileComponentDiagnostics } from './mobile-diagnostics/MobileComponentDiagnostics';
import tiresImg from 'src/assets/images/mobile-fleet-tires.svg';
import { PartBox } from './mobile-diagnostics/PartBox';
import { HEALTH_STATUS, TOAST_STATUS } from 'src/const';
import { MobileAssetTiresHealth, SiteMap } from 'src/components/newFolderStructure/assets-studio';
import { MobileFleetSensorData } from 'src/features/mobile-fleet-sensor-data/MobileFleetSensorData';
import { MaintenanceTable } from 'src/features/maintenance-data/maintenance-table/MaintenanceTable';
import { useLazyGetMobileDiagnosticsQuery } from 'src/app/api/diagnosticApi';
import { cloneDeep } from 'lodash';
import { Loading } from 'src/components/Loading';

const importOrder = [
  HEALTH_STATUS.CRITICAL,
  HEALTH_STATUS.ALARM,
  HEALTH_STATUS.MONITOR,
  HEALTH_STATUS.HEALTHY,
  HEALTH_STATUS.NOT_AVAILABLE,
];

export const MobileMachineComponentsPage = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { siteId } = useParams<string>();
  const { site } = getSiteById(siteId);
  const tabIndex = Number(searchParams.get('tabIndex'));

  const [selectedComponent, setSelectedComponent] = useState<any>(null);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(tabIndex || 0);
  const [revision, setRevision] = useState<number>(0);

  const [sortedMobileAsset, setSortedMobileAsset] = useState<any>(null);
  const [parts, setParts] = useState<any[] | null>(null);

  const [fetchMobileDiagnostics, { data: mobileAsset, isLoading: isMobileAssetLoading }] =
    useLazyGetMobileDiagnosticsQuery();

  useEffect(() => {
    if (siteId === 'york') {
      fetchMobileDiagnostics(
        {
          site_id: siteId,
        },
        true
      )
        .unwrap()
        .catch((error) => {
          if (!toast.isActive(siteId)) {
            toast({
              id: siteId,
              status: TOAST_STATUS.Error,
              title: `Failed to load mobile diagnostics for site: ${siteId}`,
              description: t('general.errors.communication'),
            });
          }
          Sentry.captureException(
            error?.data?.message || error?.error || error?.message || error?.originalError || error
          );
        });
    }
  }, [siteId]);

  useEffect(() => {
    if (mobileAsset) {
      const mobileAssetClone = cloneDeep(mobileAsset);
      mobileAssetClone.components.sort((a: any, b: any) => {
        const idx1 = importOrder.indexOf(a.health_status.toLowerCase());
        const idx2 = importOrder.indexOf(b.health_status.toLowerCase());
        const diff = idx1 - idx2;
        if (diff) {
          return diff;
        } else {
          return a.component_name.localeCompare(b.component_name);
        }
      });

      setSortedMobileAsset(mobileAssetClone);

      const parts: any[] = [];
      mobileAssetClone.components.forEach((component: any) => {
        component.Parts?.forEach((part: any) => {
          parts.push(part);
        });
      });

      setParts(parts);
    }
  }, [mobileAsset]);

  useEffect(() => {
    setActiveTabIndex(tabIndex);
  }, [tabIndex]);

  const onTabChange = (index: number) => {
    setSearchParams((params) => {
      return {
        ...params,
        tabIndex: index,
      };
    });
    setRevision(revision + 1);
    setActiveTabIndex(index);
    // reset sidebar (components cannot be selected when not under of diagnostics tab)
    if (index !== 0) setSelectedComponent(null);
  };

  return (
    <Suspense>
      <Header>
        <ErrorBoundary FallbackComponent={Fallback}>
          {site && sortedMobileAsset && (
            <Toolbar
              siteId={siteId}
              siteName={site.site_name}
              machineName={sortedMobileAsset.asset_ui_name}
              machineId={sortedMobileAsset.asset_id}
              tabIndex={activeTabIndex}
              isTopFixed
              latestDiagnosticDate={format(new Date(), 'yyyy-MM-dd')}
              assetHealthStatus={sortedMobileAsset.health_status.toLowerCase()}
              hideFilters
            />
          )}
        </ErrorBoundary>
      </Header>
      {/* @TODO: update layouts - this is a temp solution for the conferance */}
      <Box {...containerProps} w={activeTabIndex === 3 ? 'fit-content' : ''}>
        {!isMobileAssetLoading && sortedMobileAsset && parts ? (
          <Fade in={true} transition={{ enter: { delay: 0 }, exit: { delay: 0 } }} unmountOnExit>
            <Tabs index={activeTabIndex} variant="unstyled" onChange={onTabChange} isLazy mb={0}>
              <ErrorBoundary FallbackComponent={Fallback}>
                <HStack
                  justifyContent="space-between"
                  position="sticky"
                  top={{ base: '6rem', xl: '3.5rem', '1.5xl': '3.5rem' }}
                  bgColor={activeTabIndex === 3 ? '' : '#fff'} // temp - hack to hide white background on map
                  pb="24px"
                  pt="32px"
                  zIndex={1}
                >
                  <Hide below="xl">
                    <ComponentTabList
                      isMobile={true}
                      selectedComponent={'mobile_asset'}
                      isComponentHasCameraSensors={false}
                      hasCamera={false}
                    />
                  </Hide>
                </HStack>
              </ErrorBoundary>

              <HStack alignItems="stretch" spacing={{ base: 0, xl: 4, '1.5xl': 4 }}>
                <Box position="sticky" top={{ base: '6rem', xl: '9.9375rem', '1.5xl': '9.9375rem' }}>
                  <Hide below="xl">
                    <ErrorBoundary FallbackComponent={Fallback}>
                      <Box h="full">
                        <MobileComponentsSidebar
                          onClick={() => setActiveTabIndex(0)}
                          assetName={sortedMobileAsset.asset_ui_name}
                          selectedComponent={selectedComponent}
                          setSelectedComponent={setSelectedComponent}
                          components={sortedMobileAsset.components}
                          element={(component: any, index: number) => (
                            <MobileComponentSidebarItem
                              key={index}
                              component={component}
                              selectedComponent={selectedComponent}
                              setSelectedComponent={setSelectedComponent}
                              setActiveTabIndex={setActiveTabIndex}
                            />
                          )}
                        />
                      </Box>
                    </ErrorBoundary>
                  </Hide>
                </Box>

                <Box w="full">
                  <TabPanels h="full" w="full" bgColor="transparent">
                    <TabPanel css={tabPanelStyle} pt={0} pb={0} h="full">
                      <ErrorBoundary FallbackComponent={Fallback}>
                        {selectedComponent !== null ? (
                          <MobileAssetTiresHealth />
                        ) : (
                          <Box display="flex" flexDirection="column" gap="1rem" h="full">
                            <Box display="flex" gap="1rem" alignItems="stretch">
                              <MobileComponentDiagnostics
                                selectedComponent={selectedComponent}
                                assetHealthStatus={sortedMobileAsset.health_status}
                                diagnostics={sortedMobileAsset.diagnostics_trend}
                                assetId={sortedMobileAsset.asset_id}
                              />
                              <Box bg="#E6E8EC" p="0.5rem" flex="1" borderRadius="8px" maxW="500px">
                                <Box p="0.5rem" pb="0.875rem" fontSize="14px" fontWeight={600}>
                                  Lifespan
                                </Box>
                                <Image src={tiresImg} pointerEvents="none" userSelect="none" />
                              </Box>
                            </Box>
                            <Box bg="#E6E8EC" p="0.5rem" flex="1" borderRadius="8px">
                              <Box p="0.5rem" fontSize="14px" fontWeight={600}>
                                Parts Overview
                              </Box>
                              <SimpleGrid columns={3} spacing={2}>
                                {parts.map((part, idx) => (
                                  <PartBox part={part} key={idx} />
                                ))}
                              </SimpleGrid>
                            </Box>
                          </Box>
                        )}
                      </ErrorBoundary>
                    </TabPanel>
                    <TabPanel pt={0} css={tabPanelStyle}>
                      <ErrorBoundary FallbackComponent={Fallback}>
                        <MobileFleetSensorData selectedComponent={selectedComponent} />
                      </ErrorBoundary>
                    </TabPanel>
                    <TabPanel pt={0} css={tabPanelStyle}>
                      <ErrorBoundary FallbackComponent={Fallback}>
                        <MaintenanceTable />
                      </ErrorBoundary>
                    </TabPanel>
                    <TabPanel
                      css={{ ...tabPanelStyle }}
                      // @TODO: move to style file
                      sx={{
                        position: 'absolute',
                        pt: 6,
                        top: '34px',
                        left: {
                          base: '-8px', // pull left when no sidebar
                          xl: '65px',
                        },
                        overflow: 'visible',
                        width: {
                          base: 'calc(100% + 8px)',
                          xl: 'calc(100% - 65px)',
                        },
                        height: 'calc(100% - 34px)', // to prevent oveflow
                        zIndex: '0',
                      }}
                    >
                      <ErrorBoundary FallbackComponent={Fallback}>
                        <SiteMap />
                      </ErrorBoundary>
                    </TabPanel>
                  </TabPanels>
                </Box>
              </HStack>
            </Tabs>
          </Fade>
        ) : (
          <Loading />
        )}
      </Box>
    </Suspense>
  );
};

const containerProps = {
  w: 'full',
  pt: { base: '8rem', xl: '3.5rem', '1.5xl': '3.5rem' },
  pl: { base: 0, xl: '1rem', '1.5xl': '2rem' },
  pb: { base: 4, xl: '1rem', '1xl': '2rem' },
  pr: { base: 0, xl: '1rem', '1.5xl': '2rem' },
};

const tabPanelStyle = css`
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
`;
