import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import { format } from 'date-fns';
import { locations } from 'src/app/locations';
import { HealthStatusIndicator } from 'src/components/StateIndicator';
import { AssetFaultType } from 'src/pages/machines/components/AssetFaultType';
import { REGULAR_DATE_FORMAT } from 'src/const';

type MobileMachineItemProps = {
  item: any;
};

export const MobileMachineItem = ({ item }: MobileMachineItemProps) => {
  const navigate = useNavigate();
  const { siteId } = useParams();

  const onMachineItemClick = () => {
    if (item.clickable) {
      navigate(generatePath(locations.protected.sites.components, { siteId, machineId: item.asset_id }));
    }
  };

  return (
    <HStack
      justifyContent="space-between"
      alignItems="center"
      bg="white"
      borderRadius="lg"
      boxShadow="sm"
      height="100%"
      px="1rem"
      py="0.75rem"
      spacing={2.5}
      cursor="pointer"
      onClick={onMachineItemClick}
      _hover={{
        boxShadow: 'lg',
      }}
    >
      <HStack alignItems="center">
        <Box display="flex" flexDirection="column" gap="3px" w="250px">
          <Text fontSize="14px" fontWeight={600}>
            {item.asset_name}
          </Text>
          <Text fontSize="12px" color="#55687D" fontWeight="500">
            Last verified: {format(new Date(), REGULAR_DATE_FORMAT)}
          </Text>
        </Box>

        <Box display="flex" flexDirection="column" gap="3px" w="140px">
          <Text fontSize="14px" fontWeight={600}>
            {item.asset_type}
          </Text>
          <Text fontSize="12px" color="#55687D" fontWeight="500">
            {item.asset_manufactor}
          </Text>
        </Box>

        <Box display="flex" flexDirection="column" gap="5px" w="140px">
          <HealthStatusIndicator type={item.health_status.toLowerCase()} />
        </Box>

        <HStack alignItems="center" gap="10px">
          {item.faulty_components.map((component: any, idx: number) => (
            <AssetFaultType
              faultType={component.component_id}
              variant={component.health_status.toLowerCase()}
              key={idx}
            />
          ))}
        </HStack>
      </HStack>

      <Text fontSize="14px" fontWeight={600} color="#6667DD">
        {item.sensors_health_status.working} / {item.sensors_health_status.total} Data Received
      </Text>
    </HStack>
  );
};
