import React, { useMemo } from 'react';
import { VStack } from '@chakra-ui/react';
import { HEALTH_STATUS } from 'src/const';
import { MobileMachinesGroup } from './MobileMachinesGroup';

type MobileMachinesListProps = {
  assets: Array<any>;
};

export const MobileMachinesList = ({ assets }: MobileMachinesListProps) => {
  const importOrder = [
    HEALTH_STATUS.CRITICAL,
    HEALTH_STATUS.ALARM,
    HEALTH_STATUS.MONITOR,
    HEALTH_STATUS.HEALTHY,
    HEALTH_STATUS.NOT_AVAILABLE,
  ];

  const sortByObject = importOrder.reduce((obj: any, item, index) => {
    return {
      ...obj,
      [item]: index,
    };
  }, {});

  const assetsUnhealthy = useMemo(
    () =>
      assets
        ? assets
            .filter(
              ({ health_status }) =>
                ![HEALTH_STATUS.HEALTHY, HEALTH_STATUS.MONITOR, HEALTH_STATUS.NOT_AVAILABLE].includes(
                  health_status.toLowerCase()
                )
            )
            .sort((a: any, b: any) => {
              if (sortByObject[a.health_status.toLowerCase()] > sortByObject[b.health_status.toLowerCase()]) return 1;
              if (sortByObject[a.health_status.toLowerCase()] < sortByObject[b.health_status.toLowerCase()]) return -1;
              if (a.asset_name > b.asset_name) return 1;
              if (a.asset_name < b.asset_name) return -1;

              return 0;
            })
        : [],
    [assets]
  );

  const assetsHealthy = useMemo(
    () =>
      assets
        ? assets
            .filter(({ health_status }) =>
              [HEALTH_STATUS.HEALTHY, HEALTH_STATUS.MONITOR, HEALTH_STATUS.NOT_AVAILABLE].includes(
                health_status.toLowerCase()
              )
            )
            .sort((a: any, b: any) => {
              if (sortByObject[a.health_status.toLowerCase()] > sortByObject[b.health_status.toLowerCase()]) return 1;
              if (sortByObject[a.health_status.toLowerCase()] < sortByObject[b.health_status.toLowerCase()]) return -1;
              if (a.asset_name > b.asset_name) return 1;
              if (a.asset_name < b.asset_name) return -1;

              return 0;
            })
        : [],
    [assets]
  );

  return (
    <VStack spacing={4} position="relative" height="calc(100vh - 13rem)" overflowY="auto">
      {assetsUnhealthy.length && (
        <MobileMachinesGroup title="page.machines.groups.unhealthy" assets={assetsUnhealthy} />
      )}

      {assetsHealthy.length && <MobileMachinesGroup title="page.machines.groups.healthy" assets={assetsHealthy} />}
    </VStack>
  );
};
