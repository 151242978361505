import React from 'react';
import { HorizontalProgressBar } from '../../../../common';

type AssetStatusCardBarGraphPropsType = {
  progressValue: number;
  containerWidth: number;
};

export const AssetStatusCardBarGraph = ({ progressValue, containerWidth }: AssetStatusCardBarGraphPropsType) => {
  return <HorizontalProgressBar progressValue={progressValue} containerWidth={containerWidth} />;
};
