export enum PositionEnum {
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_CENTER = 'bottom-center',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  TOP_CENTER = 'top-center',
  CENTER = 'center-center',
}

export enum LayoutEnum {
  HORIZONTILE = 'horizontile',
  VERTICAL = 'vertical',
}
