import { VStack, Divider } from '@chakra-ui/react';
import React from 'react';
import { paneStyle } from '../common/CardPane.style';
import { AssetStatusCard } from '../AssetStatusCard';

type CardPaneTypeProps = {
  data: any[];
};

export default function CardPane({ data }: CardPaneTypeProps) {
  return (
    <VStack sx={paneStyle} justifyContent={'space-around'} h="full" maxW="30%">
      {data.map((tire, index) => (
        <>
          <AssetStatusCard key={tire.id} data={tire}></AssetStatusCard>
          {index === 0 && <Divider my="50px" borderWidth="1px" borderColor="gray.400" />}
        </>
      ))}
    </VStack>
  );
}
