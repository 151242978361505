import React, { Suspense, useEffect } from 'react';
import { LoadScript, GoogleMap } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { MapViewLayerEnum } from 'src/types/newFolderStructure';
import { API_KEY, GMP_OPTIONS, MAP_CONTAINER_STYLE } from './site-map-config/config';
import { MapControllers } from './site-map-controllers/MapControllers';
import { useRenderSiteMapData, useSiteMap } from 'src/hooks/newFolderStructure';
import { Box, Spinner, VStack } from '@chakra-ui/react';

export const SiteMap = () => {
  const { onLoad, isLoading } = useSiteMap();
  const { renderMulticoloredPolylines, renderHeatMap } = useRenderSiteMapData();
  const { selectedMapViewLayer, center, zoom } = useSelector((state: RootState) => state.siteMap);

  return (
    <LoadScript googleMapsApiKey={`${API_KEY}`} libraries={['visualization']}>
      {isLoading ? (
        <Box position={'absolute'} left={'50%'} top={'50%'}>
          Loading Map...
        </Box>
      ) : (
        <GoogleMap
          options={GMP_OPTIONS}
          mapContainerStyle={MAP_CONTAINER_STYLE}
          center={center}
          zoom={zoom}
          mapTypeId={'satellite'}
          onLoad={onLoad}
        >
          {selectedMapViewLayer === MapViewLayerEnum.SPEED && renderMulticoloredPolylines()}
          {selectedMapViewLayer === MapViewLayerEnum.TIRE_WEAR_HEATMAP && renderHeatMap()}
          <MapControllers />
        </GoogleMap>
      )}
    </LoadScript>
  );
};
