import React from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { generateLinearGradientFromObject } from 'src/utils/newFolderStructure';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { MapLineIndexColors, MapLineIndexLabels } from 'src/constants/newFolderStructure/mobile-fleet/site-map';

export const MapLineIndex = () => {
  const { selectedMapViewLayer } = useSelector((state: RootState) => state.siteMap);
  const background = generateLinearGradientFromObject(MapLineIndexColors[selectedMapViewLayer]);
  return (
    <HStack
      sx={{ color: 'white', fontWeight: 500, padding: 1.5, minW: '400px', whiteSpace: 'nowrap' }}
      translateX={'-50%'}
      transform={'auto'}
    >
      <Box>{MapLineIndexLabels[selectedMapViewLayer].min}</Box>
      <Box
        sx={{ background, height: '16px', width: '250px', px: '20px', border: '1px solid #c3c3c3', borderRadius: 8 }}
      ></Box>{' '}
      <Box>{MapLineIndexLabels[selectedMapViewLayer].max}</Box>
    </HStack>
  );
};
