import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetHeatMapDataQuery, useLazyGetSpeedMapDataQuery } from 'src/app/api/mapApi';
import {
  setZoom,
  setStrokeWeight,
  setGMPInstance,
  setHeatMapData,
  setCenter,
  setSelectedMapViewLayer,
  resetMap,
  setSpeedMapData,
} from 'src/app/slices/siteMapSlice';
import { RootState } from 'src/app/store';

export const useSiteMap = () => {
  const dispatch = useDispatch();
  const { mapInstance } = useSelector((state: RootState) => state.siteMap);
  const [fetchHeatMapData, { data: heatMapData, isLoading: isLoadingHeatMap }] = useLazyGetHeatMapDataQuery();
  const [fetchSpeedMapData, { data: speedMapData, isLoading: isLoadingSpeedMap }] = useLazyGetSpeedMapDataQuery();

  useEffect(() => {
    fetchHeatMapData({}, true);
    fetchSpeedMapData({}, true);
    return () => {
      //Reset map on unmount
      dispatch(resetMap());
    };
  }, []);

  useEffect(() => {
    if (heatMapData && mapInstance) {
      const data = heatMapData?.data?.map((el: any) => {
        return { location: new google.maps.LatLng(el.location.lat, el.location.lng), weight: el.weight };
      });
      dispatch(setHeatMapData(data));
    }
    if (speedMapData && speedMapData?.data?.[0]?.data?.[0]?.segments)
      dispatch(setSpeedMapData(speedMapData.data[0].data[0].segments));
  }, [heatMapData, speedMapData, mapInstance]);

  // Handle zoom changes and update the stroke weight accordingly
  const handleZoomChanged = useCallback(() => {
    if (mapInstance) {
      const newZoom = mapInstance.getZoom() || 0;
      // dispatch(setZoom(newZoom));
      // Dynamically adjust stroke weight based on zoom
      const newStrokeWeight = Math.min(newZoom, newZoom / 1.5);
      dispatch(setStrokeWeight(newStrokeWeight));
    }
  }, [mapInstance]);

  // When google is defined, generate heatmap data
  const onLoad = useCallback(
    (mapInstance: google.maps.Map) => {
      dispatch(setGMPInstance(mapInstance));
    },
    [mapInstance]
  );

  return { handleZoomChanged, onLoad, isLoading: isLoadingSpeedMap || isLoadingSpeedMap };
};
