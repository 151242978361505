import React, { useRef } from 'react';
import { AssetStatusCardContainer } from './AssetStatusCardContainer/AssetStatusCardContainer';
import { AssetStatusCardHeader } from './AssetStatusCardHeader/AssetStatusCardHeader';
import { AssetStatusCardDescription } from './AssetStatusCardDescription/AssetStatusCardDescription';
import { AssetStatusCardBarGraph } from './AssetStatusCardBarGraph/AssetStatusCardBarGraph';
import { useContainerWidth } from 'src/hooks/newFolderStructure';

type AssetStatusCardPropsType = {
  data: any;
};
export const AssetStatusCard = ({ data }: AssetStatusCardPropsType) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const containerWidth = useContainerWidth(containerRef);
  return (
    <AssetStatusCardContainer ref={containerRef} currentElId={data.id}>
      <AssetStatusCardHeader {...data} />
      <AssetStatusCardDescription {...data.lifeSpan} />
      <AssetStatusCardBarGraph
        progressValue={(100 * data.lifeSpan.current) / data.lifeSpan.max}
        containerWidth={containerWidth}
      />
    </AssetStatusCardContainer>
  );
};
