export const useTireLifespan = (lifespan: {
  max: number;
  current: number;
}): { status: string; lifespanRatio: number } => {
  if (!lifespan || !lifespan.max) {
    console.error(
      'useTireLifespan requires lifespan to be provided or lifespan max value is invalid (cannot process nullish or 0 value)'
    );
    return {
      status: 'unknown',
      lifespanRatio: -1,
    };
  }
  const usageRatio = +lifespan.current / +lifespan.max;
  return {
    status: usageRatio
      ? usageRatio <= 0.3
        ? 'healthy'
        : usageRatio > 0.3 && usageRatio < 0.6
        ? 'monitor'
        : 'critical'
      : 'unknown',
    lifespanRatio: usageRatio,
  };
};
