export const cardContainerStyle = {
  border: '2px solid #E6E8EC',
  width: '100%',
  minH: '120px',
  padding: '16px',
  background: '#ffffff',
  borderRadius: '8px',
  my: 2,
  _hover: {
    cursor: 'pointer',
  },
};
