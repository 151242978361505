import React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  Grid,
  GridItem,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { DMArrowDownFilled, DMArrowUpFilled } from 'src/components/Icons';
import { SENSOR_FUSION_OPTIONS_TYPE } from 'src/features/sensor-fusion/utils';
import { TrendsSensorsOptionsList } from 'src/features/sensor-fusion/TrendsSensorsOptionsList';

interface TrendsSensorsFilterPopoverProps extends ButtonProps {
  traces: any;
  trendOptions?: any;
  vibrationSensorOptions: any;
  operationalSensorOptions: any;
  setTrendOptions: React.Dispatch<React.SetStateAction<any[] | undefined>>;
  setVibrationSensorOptions: React.Dispatch<React.SetStateAction<any[] | undefined>>;
  setOperationalSensorOptions: React.Dispatch<React.SetStateAction<any[] | undefined>>;
  isFetching: boolean;
}

const TrendsSensorsFilterPopover = ({
  traces,
  trendOptions,
  vibrationSensorOptions,
  operationalSensorOptions,
  setTrendOptions,
  setVibrationSensorOptions,
  setOperationalSensorOptions,
  isFetching,
  ...rest
}: TrendsSensorsFilterPopoverProps) => {
  return (
    <Popover placement="bottom-start" isLazy>
      {({ isOpen }) => (
        <>
          <PopoverTrigger>
            <Button variant="unstyled" fontSize="0.875rem" isDisabled={isFetching} {...rest}>
              <HStack spacing={1}>
                <Box pr={1} color={isOpen ? 'DMPrimaryColor' : undefined}>
                  Trends & Sensors
                </Box>
                {!isFetching ? (
                  <Box
                    bgColor="DMPrimaryColor"
                    px={2}
                    py={0.5}
                    borderRadius="full"
                    color="white"
                    fontSize="0.68rem"
                    fontWeight="600"
                  >
                    {traces.length}
                  </Box>
                ) : null}

                {isOpen ? (
                  <DMArrowUpFilled fontSize="1.2rem" color="DMPrimaryColor" />
                ) : (
                  <DMArrowDownFilled fontSize="1.2rem" />
                )}
              </HStack>
            </Button>
          </PopoverTrigger>

          <PopoverContent borderRadius="2xl" w="full" minW="25rem" boxShadow="md" border="0">
            <PopoverBody p={3}>
              <Grid
                templateColumns={trendOptions?.length || vibrationSensorOptions?.length ? '2fr 1fr' : 'auto'}
                gap={4}
              >
                {trendOptions?.length || vibrationSensorOptions?.length ? (
                  <GridItem>
                    <Box bgColor="#f0f1f2" borderRadius="xl" p={3} pt={0} h="full">
                      <HStack w="full" spacing={4} alignItems="flex-start">
                        <TrendsSensorsOptionsList
                          type={SENSOR_FUSION_OPTIONS_TYPE.Trends}
                          title="Trends"
                          options={trendOptions}
                          setTrendOptions={setTrendOptions}
                          setVibrationSensorOptions={setVibrationSensorOptions}
                          setOperationalSensorOptions={setOperationalSensorOptions}
                        />

                        <TrendsSensorsOptionsList
                          type={SENSOR_FUSION_OPTIONS_TYPE.VibrationSensors}
                          title="Sensor name"
                          options={vibrationSensorOptions}
                          setTrendOptions={setTrendOptions}
                          setVibrationSensorOptions={setVibrationSensorOptions}
                          setOperationalSensorOptions={setOperationalSensorOptions}
                        />
                      </HStack>
                    </Box>
                  </GridItem>
                ) : null}

                <GridItem>
                  <TrendsSensorsOptionsList
                    type={SENSOR_FUSION_OPTIONS_TYPE.OperationalSensors}
                    title="Operational sensors"
                    options={operationalSensorOptions}
                    setTrendOptions={setTrendOptions}
                    setVibrationSensorOptions={setVibrationSensorOptions}
                    setOperationalSensorOptions={setOperationalSensorOptions}
                  />
                </GridItem>
              </Grid>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default TrendsSensorsFilterPopover;
