import React from 'react';
import { Box } from '@chakra-ui/react';
import { cardDescriptionStyle } from './AssetStatusCardDescription.style';

type AssetStatusCardHeaderPropsType = {
  max: number;
  current: number;
};

export const AssetStatusCardDescription = ({ max, current }: AssetStatusCardHeaderPropsType) => {
  return (
    <Box sx={cardDescriptionStyle}>{`${current.toLocaleString()} Out of ${max.toLocaleString()} Hours Remaining`}</Box>
  );
};
