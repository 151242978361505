export const generateLinearGradientFromObject = (colors: any, direction = 'to right') => {
  const colorEntries = Object.entries(colors);
  const totalColors = colorEntries.length;

  // Map each color to its respective percentage based on its position
  const gradientStops = colorEntries.map(([_, color], index) => {
    const percentage = (index / (totalColors - 1)) * 100;
    return `${color} ${percentage}%`;
  });

  // Join the gradient stops into a CSS linear-gradient string with direction
  return `linear-gradient(${direction}, ${gradientStops.join(', ')})`;
};
