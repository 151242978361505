import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { format, sub } from 'date-fns';
import * as Sentry from '@sentry/react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Fade,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
  useToast,
  Hide,
  Button,
  Image,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { Asset } from 'src/types/assets';
import {
  INITIAL_FILTERS,
  NOT_MONITORED_DAYS,
  SENSOR_STATUS,
  SENSOR_TYPE,
  STATUS_CHANGED,
  TOAST_STATUS,
} from 'src/const';
import { getSiteById } from 'src/app/queries';
import { analytics } from 'src/analytics';
import {
  useLazyGetAssetsQuery,
  useLazyGetDiagnosticsQuery,
  useLazyGetMobileAssetsQuery,
  useLazyGetMobileDiagnosticsQuery,
} from 'src/app/api/diagnosticApi';
import { setSearchValue } from 'src/app/slices/applicationSlice';
import { MachinesList } from 'src/pages/machines/components/MachinesList';
import { Toolbar } from 'src/components/Toolbar';
import { searchValueSelector } from 'src/app/store';
import { Header } from 'src/components/Header';
import Fallback from 'src/components/Fallback';
import { FilterBar } from 'src/components/filter-bar/FilterBar';
import { Loading } from 'src/components/Loading';
import {
  getAmountByStatus,
  getSensorsOverallStatus,
  getTotalAssetsWithStatusChanged,
  getAmountByCamera,
} from './utils';
import { css } from '@emotion/react';
import { SiteBusinessInsightsStatistics } from 'src/pages/machines/business-insights/SiteBusinessInsightsStatistics';
import { BusinessInsightsDropdown } from 'src/pages/machines/business-insights/BusinessInsightsDropdown';
import { SiteMap } from 'src/components/newFolderStructure/assets-studio/mobile-fleet';
import { AssetsViewDropdown } from 'src/pages/machines/components/AssetsViewDropdown';
import { AssetsView } from 'src/types/assets';
import { MobileMachinesList } from './mobile-assets/MobileMachinesList';
import { useLazyGetMaintenanceDataQuery } from 'src/app/api/maintenanceApi';
import maintMock from 'src/assets/images/MaintMock.svg';
import maintMockUpcomingTable from 'src/assets/images/MaintMockUpcomingOrders.svg';
import maintMockButtons from 'src/assets/images/MaintMockButtons.svg';
import { DMCloseIcon } from 'src/components/Icons';

const sensorsAmountInitial = {
  all: 0,
  critical: 0,
  healthy: 0,
};

export const MachinesPage = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const { siteId } = useParams();
  const dispatch = useDispatch();
  const { site } = getSiteById(siteId);
  const siteLastVerified = site?.latest_diagnostic_created_at;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedStatuses, setSelectedStatuses] = useState<Record<string, boolean>>(INITIAL_FILTERS);
  const [selectedStatusChanged, setSelectedStatusChanged] = useState<Record<string, boolean>>(STATUS_CHANGED);

  const [selectedSensors, setSelectedSensors] = useState<string>('all');
  const [totalAssetsSensors, setTotalAssetsSensors] = useState(sensorsAmountInitial);

  const selectedStatusesKeys = Object.keys(selectedStatuses).filter((key) => selectedStatuses[key]);
  const [clonedAssets, setClonedAssets] = useState<any>();
  const [fetchAssets, { data: assets, isLoading }] = useLazyGetAssetsQuery();
  const [fetchMobileAssets, { data: mobileAssets, isLoading: isMobileAssetsLoading }] = useLazyGetMobileAssetsQuery();

  const assetsAmountByStatus = useMemo(() => clonedAssets && getAmountByStatus(clonedAssets), [clonedAssets]);
  const assetsAmountByCamera = useMemo(() => clonedAssets && getAmountByCamera(clonedAssets), [clonedAssets]);

  const assetsAmountByStatusChanged = useMemo(
    () => clonedAssets && getTotalAssetsWithStatusChanged(clonedAssets, siteLastVerified),
    [clonedAssets]
  );

  const [fetchDiagnostics] = useLazyGetDiagnosticsQuery();
  const [fetchMobileDiagnostics] = useLazyGetMobileDiagnosticsQuery();
  const [fetchMaintenanceData] = useLazyGetMaintenanceDataQuery();
  const searchValue = useSelector(searchValueSelector);
  const [searchResults, setSearchResults] = useState<any>([]);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const [selectedAssetsView, setSelectedAssetsView] = useState<AssetsView>('grid');

  useEffect(() => {
    const all =
      clonedAssets &&
      clonedAssets.reduce((total: number, item: any) => {
        return total + item?.sensors_health_status?.length;
      }, 0);

    const critical =
      clonedAssets &&
      clonedAssets.reduce((total: number, item: any) => {
        return (
          total +
          item.sensors_health_status.filter((item: any) => {
            return item.health_status === SENSOR_STATUS.OFFLINE;
          }).length
        );
      }, 0);

    const healthy =
      clonedAssets &&
      clonedAssets.reduce((total: number, item: any) => {
        return (
          total +
          item.sensors_health_status.filter((item: any) => {
            return item.health_status === SENSOR_STATUS.ONLINE;
          }).length
        );
      }, 0);

    setTotalAssetsSensors({
      all,
      critical,
      healthy,
    });
  }, [clonedAssets]);

  const cutoffDate = new Date();
  cutoffDate.setDate(cutoffDate.getDate() - NOT_MONITORED_DAYS);

  const isNotMonitored = (asset: any, siteLastVerified: any) => {
    return (
      siteLastVerified &&
      (new Date(asset.latest_diagnostic_created_at) < new Date(siteLastVerified) ||
        new Date(siteLastVerified) < cutoffDate)
    );
  };

  const filterAssetsByStatusAndSensors = () => {
    if (!selectedStatuses.all) {
      return clonedAssets?.filter((item: any) => item.health_status === selectedStatusesKeys[0]);
    }

    if (selectedStatusChanged.health_status_changed) {
      return clonedAssets?.filter((item: any) => item.health_status_changed === true);
    }
    if (selectedStatusChanged.not_monitored) {
      return clonedAssets?.filter((item: any) => isNotMonitored(item, siteLastVerified));
    }

    if (selectedSensors !== 'all') {
      if (selectedSensors === SENSOR_STATUS.OFFLINE) {
        return clonedAssets?.filter(
          (item: any) => item.isSensorsOverallStatusCritical && item.sensors_health_status.length
        );
      }

      if (selectedSensors === SENSOR_STATUS.ONLINE) {
        return clonedAssets?.filter(
          (item: any) => !item.isSensorsOverallStatusCritical && item.sensors_health_status.length
        );
      }
      if (selectedSensors === SENSOR_TYPE.Camera) {
        return clonedAssets?.filter(
          (item: any) =>
            item.sensors_health_status.filter((sensor: any) => sensor.sensor_type === SENSOR_TYPE.Camera).length
        );
      }
    } else {
      return clonedAssets;
    }
  };

  const filteredAssetsByStatusAndSensors = filterAssetsByStatusAndSensors();

  const handleSearch = (value: string) => {
    const valueInLowerCase = value.toLowerCase();
    const filteredResults =
      filteredAssetsByStatusAndSensors &&
      filteredAssetsByStatusAndSensors.filter(
        (item: any) =>
          item.asset_name.toLowerCase().includes(valueInLowerCase) ||
          item.asset_ui_name.toLowerCase().includes(valueInLowerCase)
      );
    setSearchResults(filteredResults);
  };

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue, assets, selectedStatuses, selectedStatusChanged]);

  useEffect(() => {
    dispatch(setSearchValue(''));

    analytics.page({
      name: `Machines`,
      title: 'DataMind Dashboard 2.0 - Machines',
      properties: {
        siteId,
      },
    });

    if (siteId) {
      fetchAssets(
        {
          site_id: siteId,
        },
        true
      )
        .unwrap()
        .catch((error) => {
          if (!toast.isActive(siteId)) {
            toast({
              id: siteId,
              status: TOAST_STATUS.Error,
              title: `Failed to load assets for site: ${siteId}`,
              description: t('general.errors.communication'),
            });
          }
          Sentry.captureException(
            error?.data?.message || error?.error || error?.message || error?.originalError || error
          );
        });

      if (siteId === 'york') {
        fetchMobileAssets(
          {
            site_id: siteId,
          },
          true
        )
          .unwrap()
          .catch((error) => {
            if (!toast.isActive(siteId)) {
              toast({
                id: siteId,
                status: TOAST_STATUS.Error,
                title: `Failed to load mobile assets for site: ${siteId}`,
                description: t('general.errors.communication'),
              });
            }
            Sentry.captureException(
              error?.data?.message || error?.error || error?.message || error?.originalError || error
            );
          });
      }
    }
  }, [siteId]);

  useEffect(() => {
    if (assets) {
      const assetsWithSensorsOverallStatus = assets.map((asset: Asset) => {
        return {
          ...asset,
          isSensorsOverallStatusCritical: getSensorsOverallStatus(asset.sensors_health_status),
        };
      });

      setClonedAssets([...assetsWithSensorsOverallStatus]);
    }
  }, [assets]);

  useEffect(() => {
    const fromDate = sub(new Date(), { months: 6 });
    const toDate = new Date();

    if (assets) {
      for (const asset of assets) {
        if (siteId) {
          fetchDiagnostics(
            {
              site_id: siteId,
              assets_ids: asset.asset_id,
              from_date: asset.latest_diagnostic_created_at,
            },
            true
          );

          fetchDiagnostics(
            {
              site_id: siteId,
              assets_ids: asset.asset_id,
              from_date: format(fromDate, "yyyy-MM-dd'T'00:00:00"),
              to_date: format(toDate, "yyyy-MM-dd'T'00:00:00"),
            },
            true
          );
        }
      }
    }
  }, [assets]);

  useEffect(() => {
    if (siteId === 'york') {
      fetchMaintenanceData({ site_id: siteId }, true);
    }
  }, [siteId]);

  useEffect(() => {
    if (siteId === 'york') {
      fetchMobileDiagnostics({ site_id: siteId }, true);
    }
  }, [siteId]);

  const onTabChange = (index: number) => setActiveTabIndex(index);
  const siteAssetsUINames = clonedAssets
    ?.map((asset: any) => asset.asset_ui_name)
    .sort((a: any, b: any) => a.localeCompare(b));

  return (
    <Suspense>
      <Header>
        <ErrorBoundary FallbackComponent={Fallback}>
          {site ? (
            <Toolbar
              siteName={site?.site_name}
              amountByStatus={assetsAmountByStatus}
              selectedStatuses={selectedStatuses}
              setSelectedStatuses={setSelectedStatuses}
              totalAssetsSensors={totalAssetsSensors}
              selectedSensors={selectedSensors}
              setSelectedSensors={setSelectedSensors}
              isTopFixed
              amountByStatusChanged={assetsAmountByStatusChanged}
              selectedStatusChanged={selectedStatusChanged}
              setSelectedStatusChanged={setSelectedStatusChanged}
              tabIndex={activeTabIndex}
              amountByCamera={assetsAmountByCamera}
              isMobileAssetsPage={activeTabIndex === 1}
              isSiteMap={activeTabIndex === 3}
            />
          ) : null}
        </ErrorBoundary>
      </Header>

      <Box {...containerProps} sx={{ position: 'relative' }}>
        {!isLoading && assets && (siteId !== 'york' || (!isMobileAssetsLoading && mobileAssets)) ? (
          <Fade in={!isLoading} transition={{ enter: { delay: 0 }, exit: { delay: 0 } }}>
            <ErrorBoundary FallbackComponent={Fallback}>
              <Hide>
                <FilterBar
                  amountByStatus={assetsAmountByStatus}
                  selectedStatuses={selectedStatuses}
                  setSelectedStatuses={setSelectedStatuses}
                  totalAssetsSensors={totalAssetsSensors}
                  selectedSensors={selectedSensors}
                  setSelectedSensors={setSelectedSensors}
                />
              </Hide>
            </ErrorBoundary>
            <Tabs index={activeTabIndex} onChange={onTabChange} variant="unstyled" isLazy>
              <HStack justifyContent="space-between">
                {/*  @TODO: move styles to style file, move to component file */}
                <TabList
                  bgColor="#ffffff"
                  borderRadius="8px"
                  w="auto"
                  border="1px solid #e2e8f0"
                  boxShadow="sm"
                  zIndex={3}
                  sx={{
                    '& > *:last-child': { borderTopRightRadius: 8, borderBottomRightRadius: 8 },
                    '& > *:first-child': { borderTopLeftRadius: 8, borderBottomLeftRadius: 8 },
                    '& > *': {
                      fontWeight: 500,
                      bg: '#ffffff',
                      _selected: { color: '#12ffe2', bg: '#021d3d', fontWeight: 600 },
                    },
                  }}
                >
                  <Tab {...tabProps}>Fixed Assets</Tab>
                  <Tab isDisabled={siteId !== 'york'} {...tabProps}>
                    {siteId === 'york' ? 'Mobile Assets' : <Tooltip label="Coming Soon">Mobile Assets</Tooltip>}
                  </Tab>
                  <Tab {...tabProps}>Business Insights</Tab>
                  <Tab {...tabProps}>Site Map</Tab>
                  <Tab {...tabProps}>Maintenance Planning</Tab>
                </TabList>
                <Hide below="w-1024">
                  {activeTabIndex === 0 && (
                    <AssetsViewDropdown
                      selectedAssetsView={selectedAssetsView}
                      setSelectedAssetsView={setSelectedAssetsView}
                    />
                  )}
                  {activeTabIndex === 1 && (
                    <AssetsViewDropdown
                      selectedAssetsView={'list'}
                      setSelectedAssetsView={setSelectedAssetsView}
                      isDisabled
                    />
                  )}
                  {siteId === 'york' && activeTabIndex === 2 && <BusinessInsightsDropdown />}
                </Hide>
              </HStack>

              <TabPanels h="full" w="full" bgColor="transparent">
                <Fade in={!activeTabIndex} transition={{ enter: { delay: 0 }, exit: { delay: 0 } }} unmountOnExit>
                  <TabPanel css={tabPanelStyle}>
                    <ErrorBoundary FallbackComponent={Fallback}>
                      <MachinesList assets={searchResults} site={site} selectedAssetsView={selectedAssetsView} />
                    </ErrorBoundary>
                  </TabPanel>
                </Fade>

                <Fade in={!!activeTabIndex} transition={{ enter: { delay: 0 }, exit: { delay: 0 } }} unmountOnExit>
                  <TabPanel css={tabPanelStyle}>
                    <ErrorBoundary FallbackComponent={Fallback}>
                      {siteId === 'york' && <MobileMachinesList assets={mobileAssets} />}
                    </ErrorBoundary>
                  </TabPanel>
                </Fade>

                <Fade in={!!activeTabIndex} transition={{ enter: { delay: 0 }, exit: { delay: 0 } }} unmountOnExit>
                  <TabPanel css={tabPanelStyle}>
                    <ErrorBoundary FallbackComponent={Fallback}>
                      <SiteBusinessInsightsStatistics siteAssetsUINames={siteAssetsUINames} />
                    </ErrorBoundary>
                  </TabPanel>
                </Fade>
                <Fade in={!!activeTabIndex} transition={{ enter: { delay: 0 }, exit: { delay: 0 } }} unmountOnExit>
                  <TabPanel
                    css={{ ...tabPanelStyle }}
                    // @TODO: move to style file
                    sx={{
                      position: 'absolute',
                      top: '34px',
                      left: {
                        base: '-8px', // pull left when no sidebar
                        xl: 0,
                      },
                      overflow: 'visible',
                      w: '100%',
                      height: 'calc(100% - 34px)', // to prevent oveflow
                      zIndex: '0',
                    }}
                  >
                    <ErrorBoundary FallbackComponent={Fallback}>
                      <SiteMap />
                    </ErrorBoundary>
                  </TabPanel>
                </Fade>
                <Fade in={!!activeTabIndex} transition={{ enter: { delay: 0 }, exit: { delay: 0 } }} unmountOnExit>
                  <TabPanel
                    css={{ ...tabPanelStyle }}
                    // @TODO: move to style file
                    // sx={{
                    //   position: 'absolute',
                    //   top: '',
                    //   overflow: 'visible',
                    //   w: '100%',
                    //   height: 'calc(100% - 34px)', // to prevent oveflow
                    //   zIndex: '0',
                    // }}
                  >
                    <ErrorBoundary FallbackComponent={Fallback}>
                      <Box
                        sx={{
                          position: 'absolute',
                          right: '2rem',
                          top: '87.5px',
                        }}
                      >
                        <Image src={maintMockButtons} width={'100%'} />
                      </Box>
                      <Box w="full" h="full" position={'relative'}>
                        <Image src={maintMock} width={'100%'} />
                        <Button
                          variant={'ghost'}
                          sx={{
                            position: 'absolute',
                            right: '0',
                            top: '4.6%',
                            height: '5%',
                            width: '14%',
                            opacity: '0',
                          }}
                          onClick={() => {
                            onOpen();
                          }}
                        ></Button>
                        <Drawer placement={'bottom'} onClose={onClose} isOpen={isOpen}>
                          <DrawerOverlay />
                          <DrawerContent pb={4}>
                            <DrawerHeader>
                              <Box display={'flex'} justifyContent={'space-between'}>
                                <Box>Upcoming work orders</Box>
                                <Box sx={{ cursor: 'pointer' }} onClick={() => onClose()}>
                                  <DMCloseIcon boxSize={6} />
                                </Box>
                              </Box>
                              <Box display={'flex'} alignItems={'center'} gap={2}>
                                <Box sx={{ color: '#55687D', fontWeight: 600, fontSize: '16px' }}>F5 Main Pump 2</Box>
                                <Box sx={{ color: '#CC0000', fontWeight: 600, fontSize: '14px' }}>Critical</Box>
                              </Box>
                            </DrawerHeader>
                            <DrawerBody>
                              <Image src={maintMockUpcomingTable} width={'100%'} />
                            </DrawerBody>
                          </DrawerContent>
                        </Drawer>
                      </Box>
                    </ErrorBoundary>
                  </TabPanel>
                </Fade>
              </TabPanels>
            </Tabs>
          </Fade>
        ) : (
          <Loading />
        )}
      </Box>
    </Suspense>
  );
};

const tabProps = {
  fontSize: {
    base: '0.875rem',
    xl: '0.875rem',
    '1.5xl': '0.875rem',
  },
  px: {
    base: 6,
    xl: 4,
    '1.5xl': 6,
  },
  py: {
    base: 3,
    xl: 3,
    '1.5xl': 3,
  },
};

const containerProps = {
  w: 'full',
  p: { base: 0, xl: 8 },
  pt: { base: '5.5rem', xl: '5.5rem' },
  pr: 4,
};

const tabPanelStyle = css`
  padding-left: 0;
  padding-right: 0;
  padding-top: 1.5rem;
  background-color: transparent;
`;
