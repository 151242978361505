import { colors } from 'src/theme/foundations/colors';

const {
  health: { healthy, critical, alarm, monitor },
} = colors;

export const heatMapColors = {
  critical,
  alarm,
  monitor,
  healthy,
};

export const speedMapColors = {
  still: '#f8f8f8',
  slow: '#babaf8',
  average: '#7c7cf8',
  fast: '#3e3ef8',
  speedy: '#0000f8',
};

// weight to color
export const HEAT_MAP_GRANDIENT = [
  heatMapColors.healthy, // Green (weight 1)
  'rgba(0, 255, 0, 1)', // Green (fully opaque)
  heatMapColors.alarm, // Yellow (weight 2)
  heatMapColors.critical, // Red (weight 3)
];
