import { Box, Card, CardBody, Center, HStack, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { RockSizeDataTransformedEntity, RockSizeDataTransformedResponse, RockSizeLevel } from 'src/app/types/sensors';
import { config } from 'src/features/visual-ai/charts/rock-size-plot/config';
import { getLayout } from 'src/features/visual-ai/charts/rock-size-plot/layout';
import { calculateDateRange, cameraEvidenceShape, findRangeBreaks } from 'src/features/visual-ai/charts/utils';
import { HealthStatus } from 'src/types/assets';
import useScreenSize from 'src/hooks/useScreenSize';
import { ChartLegend } from 'src/features/visual-ai/components/ChartLegend';
import { PlotRelayoutEvent } from 'plotly.js';
import { getCameraSource } from 'src/features/visual-ai/utils';
import { BASE64_SVG_PREFIX, iconPlaySelectedSVG, iconPlayUnselectedSVG } from 'src/features/visual-ai/const';

interface RockSizePlotProps {
  title: string;
  noDataDescription: string;
  errorMessage?: string;
  rockSizeData: RockSizeDataTransformedResponse | undefined;
  isFetchingRockSize?: boolean;
  isLoadingRockSize?: boolean;
  isErrorRockSize?: boolean;
  revision: number;
  lastUpdateComponent?: React.ReactNode;
  latest20Diagnostics?: any;
  latestRockSizeMeasurements?: any;
  activeEvidenceResourceId?: string;
  updateActiveEvidenceResourceId?: (id: string) => void;
}

export const RockSizePlot = ({
  title,
  noDataDescription,
  errorMessage,
  rockSizeData,
  isLoadingRockSize,
  isFetchingRockSize,
  isErrorRockSize,
  revision,
  lastUpdateComponent,
  latest20Diagnostics,
  latestRockSizeMeasurements,
  activeEvidenceResourceId,
  updateActiveEvidenceResourceId,
}: RockSizePlotProps) => {
  const [dataSource, updateDataSource] = useState<RockSizeDataTransformedEntity | null>(null);
  const [dateRange, setDateRange] = useState<Array<string>>();
  const [, setUpdate] = useState(0);
  const [maxValue, setMaxValue] = useState<number>(0);
  const [rangeBreaks, setRangeBreaks] = useState<Array<any>>();
  const [levelValues, setLevelValues] = useState<Record<HealthStatus, RockSizeLevel>>();
  const screenSize = useScreenSize();

  const forceUpdate = () => setUpdate((prevState) => prevState + 1);

  const handlePlotClick = (latest20Diagnostics: any) => (data: any) => {
    if (data?.points) {
      const point = data.points[0];

      if (point?.data?.marker?.symbol === 'circle') {
        const clickedDate = new Date(point.x);
        const twoDaysInMs = 2 * 24 * 60 * 60 * 1000;

        const matchedEntry = latest20Diagnostics.find((entry: any) => {
          const entryDate = new Date(entry.diagnostic_created_at + 'Z');
          const differenceInDays = Math.abs(clickedDate.getTime() - entryDate.getTime()) / twoDaysInMs;

          return differenceInDays <= 1;
        });

        const evidenceResourceId = getCameraSource(matchedEntry.sources_info)?.evidence_resource_id;

        if (matchedEntry && evidenceResourceId) {
          updateActiveEvidenceResourceId && updateActiveEvidenceResourceId(evidenceResourceId);
        }
      }
    }
  };

  const getEvidenceIconSource = (date: string) => {
    const diagnosticsWithMeasurementsTime = latest20Diagnostics.map((item: any, index: number) => ({
      ...item,
      diagnostic_created_at: new Date(latestRockSizeMeasurements[index]).toISOString(),
    }));
    const selectedDiagnostic = diagnosticsWithMeasurementsTime.find(
      (entry: any) => getCameraSource(entry.sources_info)?.evidence_resource_id === activeEvidenceResourceId
    );
    const selectedDiagnosticDate = selectedDiagnostic?.diagnostic_created_at.split('T')[0];

    return activeEvidenceResourceId && selectedDiagnosticDate === date?.split('T')[0]
      ? BASE64_SVG_PREFIX + btoa(iconPlaySelectedSVG)
      : BASE64_SVG_PREFIX + btoa(iconPlayUnselectedSVG);
  };

  useEffect(() => {
    if (rockSizeData && rockSizeData.length > 0) {
      const entry = rockSizeData[0];
      const maxY = Math.max(...entry.measurements.y);
      const rangeBreaksResult = findRangeBreaks(entry.measurements.x);
      const calculatedDateRange = calculateDateRange(entry.start_datetime, entry.end_datetime);

      setRangeBreaks(rangeBreaksResult);
      setMaxValue(maxY);
      setDateRange(calculatedDateRange);
      updateDataSource(entry);
      setLevelValues(entry.levels);
    }
  }, [rockSizeData]);

  return (
    <Box bgColor="#E6E8EC" p={2} pt={0} borderRadius="2xl" w="full">
      <HStack py={4} pb={3} px={4} justifyContent="space-between">
        <Box fontWeight={600}>{title} Trend</Box>
        {lastUpdateComponent}
      </HStack>

      <Card w="full" boxShadow={0}>
        <CardBody p={1}>
          {dataSource && rangeBreaks && levelValues && dateRange ? (
            <Box className="rock-size-plot">
              <Plot
                useResizeHandler
                data={[
                  JSON.parse(JSON.stringify(dataSource.measurements)),
                  {
                    ...cameraEvidenceShape,
                    x: latestRockSizeMeasurements,
                    y: Array(latestRockSizeMeasurements.length).fill(25),
                  },
                ]}
                layout={{
                  ...getLayout([...dateRange], rangeBreaks, levelValues, maxValue),
                  height: activeEvidenceResourceId ? (screenSize.height - 397) / 3 : (screenSize.height - 397) / 2,
                  images: latestRockSizeMeasurements.map((date: any, index: number) => ({
                    source: getEvidenceIconSource(date),
                    x: date,
                    y: 25,
                    xref: 'x',
                    yref: 'y',
                    sizex: 2 * 24 * 60 * 60 * 10000,
                    sizey: 24,
                    xanchor: 'center',
                    yanchor: 'middle',
                    scale: 2,
                  })),
                }}
                config={config}
                style={{ width: 'auto' }}
                revision={revision}
                onClick={handlePlotClick(latest20Diagnostics)}
                onRelayout={(e: Readonly<PlotRelayoutEvent>) => {
                  if (e['xaxis.range']) {
                    forceUpdate();
                  }
                }}
              />

              <ChartLegend measurements={dataSource?.measurements || []} />
            </Box>
          ) : (
            <Box h="250px">
              <Center py="100px">
                {isErrorRockSize ? (
                  errorMessage
                ) : isFetchingRockSize || isLoadingRockSize ? (
                  <Spinner size="lg" />
                ) : !rockSizeData || !rockSizeData?.length || rockSizeData[0] === undefined ? (
                  noDataDescription
                ) : (
                  <Spinner size="lg" />
                )}
              </Center>
            </Box>
          )}
        </CardBody>
      </Card>
    </Box>
  );
};
