import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Card, CardBody, HStack } from '@chakra-ui/react';
import Plot from 'react-plotly.js';
import useScreenSize from 'src/hooks/useScreenSize';
import { getAnnotations, getAxesConfig, getShapes } from 'src/features/mobile-fleet-sensor-data/utils';
import { mobileFleetSensorDataSelectedSchemeSelector } from 'src/features/mobile-fleet-sensor-data/store/mobileFleetSensorData.slice';
import { config, layout as initialLayout, style } from 'src/features/mobile-fleet-sensor-data/config';
import { SCHEME_DATA_MAP } from 'src/features/mobile-fleet-sensor-data/const';
import { SchemesMenu } from 'src/features/mobile-fleet-sensor-data/components/SchemesMenu';
import { MobileFleetSensorDataHeader } from 'src/features/mobile-fleet-sensor-data/components/MobileFleetSensorDataHeader';
import { useLazyGetMobileFleetSensorDataQuery } from './api/mobileFleetSensorData.api';
import { Loading } from 'src/components/Loading';
import {
  MAINTENANCE_CHART_CONTEXT,
  MaintenanceChart,
} from 'src/features/maintenance-data/maintenance-chart/MaintenanceChart';
import { format } from 'date-fns';
import { diagnosticsDateFormat } from 'src/const';
import { Layout } from 'plotly.js';

export const MobileFleetSensorData = ({ selectedComponent }: { selectedComponent: any }) => {
  const screenSize = useScreenSize();
  const selectedScheme = useSelector(mobileFleetSensorDataSelectedSchemeSelector);
  const [fetchMobileFleetSensorData, { data, isLoading, isFetching }] = useLazyGetMobileFleetSensorDataQuery();
  const [dataset, setDataset] = useState<any>();
  const boxRef: any = useRef(null);
  const [fromDate] = useState<Date | undefined>(new Date('2024-09-28T00:24:17'));
  const [toDate] = useState<Date>(new Date('2024-10-27T23:32:20'));
  const [xAxisMinMaintenance, setXAxisMinMaintenance] = useState<string>();
  const [xAxisMaxMaintenance, setXAxisMaxMaintenance] = useState<string>();
  const [layout, setLayout] = useState<Partial<Layout>>();

  const onRelayout = (event: any) => {
    const xAxisRangeMin = event['xaxis.range[0]'];
    const xAxisRangeMax = event['xaxis.range[1]'];

    if (xAxisRangeMin && xAxisRangeMax) {
      const xAxisRangeMinString = format(new Date(xAxisRangeMin), diagnosticsDateFormat);
      const xAxisRangeMaxString = format(new Date(xAxisRangeMax), diagnosticsDateFormat);

      setXAxisMinMaintenance(xAxisRangeMinString);
      setXAxisMaxMaintenance(xAxisRangeMaxString);
    } else if (fromDate && toDate) {
      const fromDateString = format(new Date(fromDate), diagnosticsDateFormat);
      const toDateString = format(new Date(toDate), diagnosticsDateFormat);

      setXAxisMinMaintenance(fromDateString);
      setXAxisMaxMaintenance(toDateString);
    }
  };

  useEffect(() => {
    if (fromDate && toDate) {
      const fromDateString = format(new Date(fromDate), diagnosticsDateFormat);
      const toDateString = format(new Date(toDate), diagnosticsDateFormat);

      setXAxisMinMaintenance(fromDateString);
      setXAxisMaxMaintenance(toDateString);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    if (selectedScheme) {
      const filename = SCHEME_DATA_MAP[selectedScheme.id];

      fetchMobileFleetSensorData(
        {
          site_id: 'york',
          filename,
        },
        true
      );
    }
  }, [selectedScheme]);

  useEffect(() => {
    if (data?.data) {
      const enrichedSchemeData = data.data.map((item: any, index: number) => {
        return {
          title: item.name,
          ...item.measurements,
          type: 'scattergl',
          mode: 'lines',
          yaxis: `y${index + 1}`,
          hovertemplate: `<b>%{x}</b></br></br><b>%{y} ${
            item.y_units !== 'Category' ? item.y_units : ''
          }</b><extra></extra>`,
        };
      });

      setDataset(enrichedSchemeData);
    }
  }, [data]);

  useEffect(() => {
    if (dataset) {
      setLayout({
        ...initialLayout,
        ...getAxesConfig(dataset.length),
        ...getAnnotations(dataset, dataset.length),
        ...getShapes(dataset.length),
        height: screenSize.height - 370,
      });
    }
  }, [dataset]);

  return (
    <HStack w="full" spacing={4} alignItems="flex-start">
      <Box bgColor="#E6E8EC" w="full" p={2} pt={0} borderRadius="lg" height="calc(100vh - 215px)">
        <MobileFleetSensorDataHeader schemeName={selectedScheme?.label} />

        <Card w="full" boxShadow={0} h="calc(100% - 48px)">
          <CardBody p={1} position="relative">
            <Box ref={boxRef} className="tooltip-box" position="relative">
              {layout && dataset && (isLoading || !isFetching) ? (
                <Plot
                  useResizeHandler
                  data={dataset}
                  layout={layout}
                  style={style}
                  config={config}
                  onRelayout={onRelayout}
                />
              ) : (
                <Loading />
              )}
            </Box>

            {dataset && (isLoading || !isFetching) ? (
              <Box pt={4} pb={0} pl={7} pr={0}>
                <MaintenanceChart
                  selectedComponent={selectedComponent}
                  xAxisMin={xAxisMinMaintenance}
                  xAxisMax={xAxisMaxMaintenance}
                  context={MAINTENANCE_CHART_CONTEXT.SensorFusion}
                  wrapperRef={boxRef}
                />
              </Box>
            ) : null}
          </CardBody>
        </Card>
      </Box>

      <SchemesMenu />
    </HStack>
  );
};
