import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';

const initialState: {
  selectedScheme: Record<string, string> | undefined;
} = {
  selectedScheme: undefined,
};

export const mobileFleetSensorDataSlice = createSlice({
  name: 'mobileFleetSensorData',
  initialState,
  reducers: {
    setSelectedScheme: (state, action) => {
      state.selectedScheme = action.payload;
    },
  },
});

export const { setSelectedScheme } = mobileFleetSensorDataSlice.actions;

export const mobileFleetSensorDataSelectedSchemeSelector = (state: RootState) =>
  state.mobileFleetSensorData.selectedScheme;
