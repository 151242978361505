//@TODO - text from typography, colors from theme
export const indicatorStyle = { border: '1px solid #c3c3c3', width: '20px', height: '8px', borderRadius: 'full' };
export const containerStyle = {
  height: '100%',
  width: '300px',
  background: '#EDF2F7',
  borderRadius: 8,
  justifyContent: 'flex-start',
  alignItems: 'start',
  padding: 4,
};
export const explanationStyle = { fontSize: '14px' };
export const titleStyle = { fontWeight: 600, textTransform: 'capitalize' };
export const labelStyle = { fontWeight: 600, textTransform: 'capitalize' };
