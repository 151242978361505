import { VStack, Tag } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { setSelectedTire, setHoveredTire, resetTires } from 'src/app/slices/mobileAssetDetailedDiagnosticsSlice';
import { dispatch, RootState } from 'src/app/store';
import { CATTruckBottomView } from 'src/assets/newFolderStructure/svg';
import { useTireLifespan } from 'src/hooks/newFolderStructure';
import { colors } from 'src/theme/foundations/colors';
import { tiresComponents } from '../tires-health-components-config/tires-health-components-config';
import { useSelector } from 'react-redux';
import { assetsStatusGraphicsContainerStyle } from './AssetStatusGraphics.styles';
import { tiresData } from '../mockData';
import { truckStyle } from '../tires-health-components-config/tires-health-components-config.styles';

export const AssetStatusGraphics = () => {
  const { selectedTire, hoveredTire } = useSelector((state: RootState) => state.mobileAssetDetailedDiagnostics);
  useEffect(() => {
    return () => {
      // Reset on unmount
      dispatch(resetTires());
    };
  }, []);
  return (
    <VStack sx={assetsStatusGraphicsContainerStyle}>
      {tiresComponents.map((el) => {
        const { component, id, sx, tagSx } = el;
        const Tire = component;
        const dataItem = tiresData.find((el) => el.id === id);
        const { status } = dataItem ? useTireLifespan(dataItem?.lifeSpan) : { status: 'unknown' };
        return (
          <>
            <Tire
              onClick={() => dispatch(setSelectedTire(id))}
              onMouseEnter={() => dispatch(setHoveredTire(id))}
              onMouseLeave={() => dispatch(setHoveredTire(null))}
              key={id}
              sx={{
                ...sx,
                transform: `${selectedTire === el.id || hoveredTire === el.id ? 'scale(1.1)' : ''}`,
                opacity: `${selectedTire === el.id || hoveredTire === el.id ? '1' : '0.5'}`,
                color: colors.health[`${status}` as 'critical'],
              }}
            />
            <Tag
              variant={'solid'}
              borderRadius={'50%'}
              sx={{
                ...tagSx,
                transform: `${selectedTire === el.id || hoveredTire === el.id ? 'scale(1.2)' : ''}`,
              }}
              position={'absolute'}
              backgroundColor={colors.health[`${status}` as 'critical']}
            >
              {dataItem?.tireNumber}
            </Tag>
          </>
        );
      })}
      <CATTruckBottomView sx={truckStyle} />
    </VStack>
  );
};
