import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as echarts from 'echarts/core';
import ReactECharts from 'echarts-for-react';
import { format } from 'date-fns';
import { DD_MM_DATE_FORMAT } from 'src/const';
import { healthStatusToNumberMap } from '../const';
import { VStack, Collapse } from '@chakra-ui/react';
import { colors } from 'src/theme/foundations/colors';
import { MAINTENANCE_CHART_CONTEXT, MobileMaintenanceChart } from './MobileMaintenanceChart';
import { useSelector } from 'react-redux';
import { maintenanceChartVisible } from 'src/app/store';

interface MobileDiagnosticsChartProps {
  diagnostics: any;
  assetId: string;
}

export const MobileDiagnosticsChart = ({ diagnostics, assetId }: MobileDiagnosticsChartProps) => {
  const isMaintenanceChartVisible = useSelector(maintenanceChartVisible);
  const diagnosticsChartRef = useRef<ReactECharts>(null);
  const maintenanceChartRef = useRef<ReactECharts>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [dataZoom, setDataZoom] = useState<Record<string, number>>({ start: 0, end: 100 });
  const [chartData, setChartData] = useState<any>([]);
  const [chartOptions, setChartOptions] = useState<any>({});

  const getChartData = () => {
    const formattedDiagnostics = diagnostics.x.map((item: string, idx: number) => {
      return {
        name: format(new Date(item), 'yyyy-MM-dd'),
        value: [format(new Date(item), 'yyyy-MM-dd'), healthStatusToNumberMap[diagnostics.y[idx].toLowerCase()]],
        clicked: false,
      };
    });

    return formattedDiagnostics;
  };

  const toolbox = {
    left: 'right',
    itemSize: 12,
    top: -7,
    feature: {
      dataZoom: {
        title: {
          zoom: 'Zoom',
          back: '',
        },
        icon: {
          back: 'blank',
        },
        yAxisIndex: 'none',
        emphasis: {
          iconStyle: {
            borderColor: '#7071f3',
          },
        },
        brushStyle: {
          borderWidth: 1,
          borderColor: '#7071f3',
        },
      },
      restore: {
        emphasis: {
          iconStyle: {
            borderColor: '#7071f3',
          },
        },
      },
    },
  };

  const commonOptions = {
    height: '150px',
    grid: { top: 10, right: 16, bottom: 16, left: 24 },
    animation: false,
    dataZoom: [
      {
        type: 'inside',
      },
    ],
    xAxis: {
      type: 'time',
      boundaryGap: false,
      axisTick: false,
      splitLine: {
        show: false,
      },
      axisLine: {
        show: true,
      },
      axisLabel: {
        fontSize: 10,
        rotate: 45,
        showMaxLabel: true,
        formatter: function (value: string) {
          return format(new Date(value), DD_MM_DATE_FORMAT);
        },
      },
    },
    yAxis: {
      type: 'value',
      min: 0,
      max: 6,
      axisLine: true,
      axisLabel: true,
      splitLine: {
        show: false,
      },
    },
    visualMap: {
      show: false,
      pieces: [
        {
          gt: 5,
          lte: 6,
          color: colors.health.notAvailable,
        },
        {
          gt: 4,
          lte: 5,
          color: colors.health.notAvailable,
        },
        {
          gt: 3,
          lte: 4,
          color: colors.health.healthy,
        },
        {
          gt: 2,
          lte: 3,
          color: colors.health.monitor,
        },
        {
          gt: 1,
          lte: 2,
          color: colors.health.alarm,
        },
        {
          gt: 0,
          lte: 1,
          color: colors.health.critical,
        },
      ],
    },
  };

  useEffect(() => {
    setChartOptions({
      ...commonOptions,
      toolbox,
      xAxis: {
        ...commonOptions.xAxis,
        type: isMaintenanceChartVisible.onDiagnostics ? 'time' : 'category',
        axisLabel: {
          ...commonOptions.xAxis.axisLabel,
        },
      },
      dataZoom: [
        {
          type: 'inside',
          filterMode: 'none',
          ...dataZoom,
        },
      ],
      series: [
        {
          type: 'line',
          smooth: false,
          data: chartData,
          symbol: 'circle',
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 2,
          },
          symbolSize: 12,
          lineStyle: {
            color: '#7071f3',
            width: 2,
          },
          areaStyle: {
            color: 'rgba(112, 113, 243, 0.12)',
          },
          zlevel: 2,
        },
      ],
    });
  }, [chartData, isMaintenanceChartVisible.onDiagnostics]);

  useEffect(() => {
    setChartData([...getChartData()]);
  }, [diagnostics]);

  const onEventsHandlers = {
    datazoom: (e: any) => {
      if (e.batch?.[0]) {
        const { start, end, startValue, endValue } = e.batch[0];
        const updatedZoom: Record<string, number> = {};

        if (start !== undefined && end !== undefined) {
          updatedZoom.start = start;
          updatedZoom.end = end;
        }

        if (startValue !== undefined && endValue !== undefined) {
          updatedZoom.startValue = startValue;
          updatedZoom.endValue = endValue;
        }

        if (Object.keys(updatedZoom).length) {
          setDataZoom(updatedZoom);
        }
      }
    },
    finished: () => {
      if (fusionTrendChartElement && maintenanceChartRef.current && diagnosticsChartRef.current) {
        const maintenanceChartInstance = maintenanceChartRef.current.getEchartsInstance();
        const diagnosticsChartInstance = diagnosticsChartRef.current.getEchartsInstance();

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        echarts.connect([maintenanceChartInstance, diagnosticsChartInstance]);

        diagnosticsChartRef.current.getEchartsInstance().on('restore', (e: any) => {
          diagnosticsChartRef.current?.getEchartsInstance().dispatchAction({
            type: 'dataZoom',
            start: 0,
            end: 100,
          });

          setDataZoom({ start: 0, end: 100 });
        });
      }
    },
    restore: () => setDataZoom({ start: 0, end: 100 }),
  };

  const fusionTrendChartElement = useMemo(
    () => (
      <ReactECharts
        ref={diagnosticsChartRef}
        echarts={echarts}
        option={chartOptions}
        onEvents={onEventsHandlers}
        style={{
          height: '200px',
        }}
      />
    ),
    [chartOptions]
  );

  return (
    <VStack w="full" ref={wrapperRef} alignItems="stretch" spacing={1} h="full">
      {fusionTrendChartElement}

      {chartData.length > 1 && (
        <Collapse in={isMaintenanceChartVisible.onDiagnostics} animateOpacity>
          <MobileMaintenanceChart
            assetId={assetId}
            selectedComponent={null}
            xAxisMin={chartData[0].name}
            xAxisMax={chartData[chartData.length - 1].name}
            context={MAINTENANCE_CHART_CONTEXT.Diagnostics}
            wrapperRef={wrapperRef}
            diagnosticsChartRef={diagnosticsChartRef}
            maintenanceChartRef={maintenanceChartRef}
          />
        </Collapse>
      )}
    </VStack>
  );
};
